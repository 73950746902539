import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { validateRegister } from "../../_common/Validation";
import { Post } from "../../_common/httpService";
import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import mainLogo from "../../assets/slices/main_logo.svg";
import welcome from "../../assets/slices/welcome_img.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useStateValue } from "../../Context/StateProvider";
import Toast from "../../Components/Toast";
import { toast } from "react-hot-toast";
import EyeHide from "../../assets/slices/eye_hide.svg";
import EyeShow from "../../assets/slices/eye_show.svg";
import BannertwoImg from "../../assets/slices/bannertwo.png";
import BannerthreeImg from "../../assets/slices/bannerthree.png";

const Register = () => {

  let navigate = useNavigate();
  const [{ }, dispatch] = useStateValue();
  const [passwordVisibilty, setPasswordVisibilty] = useState("password")

  useEffect(() => {
    sessionStorage.clear();
  },[]);
  
  const { touched, errors, handleSubmit, values, handleChange } = useFormik({
    initialValues: {
      email: sessionStorage.getItem("email") ? sessionStorage.getItem("email") : '',
      password: "",
      isSocialMediaLogin: false,
    }, enableReinitialize: true,
    validate: validateRegister,
    onSubmit: async (values) => {
      let tenantId=sessionStorage.getItem('tenantId')?sessionStorage.getItem('tenantId'):""
      let contactId=sessionStorage.getItem('contactId')?parseInt(sessionStorage.getItem('contactId')):0
      let data = { tenants: [{...values,tenantId,contactId}], };
    
      await Post("tenant/create", data)
        .then((res) => {
          if (res.statusCode === 200) {
            sessionStorage.setItem("tenantId", res.data.tenants[0].id);
            sessionStorage.setItem("tenantToken", res.data.jwtAccessToken);
            sessionStorage.setItem("email", values.email);

            const tenantMasterParams = {
              allTenants: [
                {
                  tenantId: res.data.tenants[0].id,
                  firstname: "admin",
                  status: "Active",
                },
              ],
            };
            Post("tenantmaster/create", tenantMasterParams)
              .then((res) => {
                if (res?.statusCode === 200) {
                  toast.success("Success");
                  navigate("/signup");
                }
              })
              .catch((err) => {
                console.log('err::: ', err);
              });
          }
        })
        .catch((error) => {
          const errObj = JSON.parse(error.message);
          toast.error(errObj.data.message);
        });
    },
  });

  return (
    <>
      <Toast />
      <div className="fullScreen_bg Alignitem_center displayFlex">
        <div className="container_wrapper">
          <div className="row  Customerpanel_rectangle height_600">
            <div className="ColumnLeft col-md-5 pzero">
              <div className="deznav">
                <ul>
                  <li>
                    <NavLink to="/login" exact activeClassName="active">
                      LOGIN
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/register" activeClassName="active">
                      SIGN UP
                    </NavLink>
                  </li>
                </ul>
              </div>
              <Carousel
                infiniteLoop
                useKeyboardArrows
                autoPlay
                showThumbs={false}
                showStatus={false}
                showArrows={false}
              >
                <div className="welcome_content">
                  <img src={welcome} />
                  <h3>Welcome to Theecode</h3>
                  <p>
                    Transforming your fintech dream into reality with our seamless and innovative Tech solutions
                  </p>
                </div>

                <div className="welcome_content">
                  <img src={BannertwoImg} />
                  <h3>Welcome to Theecode</h3>
                  <p>
                    Transforming your fintech dream into reality with our seamless and innovative Tech solutions
                  </p>
                </div>

                <div className="welcome_content">
                  <img src={BannerthreeImg} />
                  <h3>Welcome to Theecode</h3>
                  <p>
                    Transforming your fintech dream into reality with our seamless and innovative Tech solutions
                  </p>
                </div>
              </Carousel>
            </div>
            <div className="col-md-7 pzero displayFlex Alignitem_center justifyContent_center whiteBg">
              <div>
                <div className="main_logo">
                  <img src={mainLogo} />
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="account_frame width_400"
                >
                  <div className="  mb_24">
                    {/* <label htmlFor="email">Email</label> */}
                    <input
                      type="text"
                      id="email"
                      placeholder="Email Address"
                      value={values.email?.replace(/[ ]/,"")}
                      className="Main_input"
                      onChange={handleChange}
                    />
                    {errors.email && touched.email ? (
                      <div className="error_text">{errors.email}</div>
                    ) : null}
                  </div>

                  {/* <div className="formGroup mb_24"> */}
                  {/* <label htmlFor="pass">Password</label> */}
                  {/* <input
                      type={passwordVisibilty}
                      id="pass"
                      name="password"
                      placeholder="Your Password"
                      className="Main_input"
                      value={values.password}
                      onChange={handleChange}
                    />
                    <div className="input-group-prepend">
                      <span className="inputGroup_text">
                        <img className="width20" src={passwordVisibilty == "password" ? EyeHide : EyeShow}
                          onClick={() => {
                            passwordVisibilty == "password"
                              ? setPasswordVisibilty("text")
                              : setPasswordVisibilty("password");
                          }} />
                      </span>
                    </div> */}
                  {/* <small>
                  Password should contain atleast one uppercase letter,
                  lowercase letter, special character and number.
                </small> */}
                  {/* {errors.password && touched.password ? (
                      <div className="error_text">{errors.password}</div>
                    ) : null}
                  </div> */}

                  <button type="submit" className="Mainbutton  ">
                    Continue
                  </button>
                  {/* <span>
                Already have an account? <a href="/login">Login</a>
              </span> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
