import React, { useEffect } from "react";
import CongratulationsIcon from "../../assets/slices/congratulations_icon.png";
import thankyou from "../../assets/slices/thankyou.svg"
import { useNavigate } from "react-router-dom";
import ThankyouAnime from "./ThankyouAnime";

const Thankyou = () => {
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      // navigate("/login")
      let tenantId = sessionStorage.getItem("tenantId");
      console.log("tenant Id::", tenantId);
      navigate(`/docs/home`);
    }, [5000])
  }, [])
  return (
    <div className="fullScreen_bg">
      <div className="container_wrapper">
        <div className="Customerpanel_rectangle height_600 displayFlex Alignitem_center justifyContent_center">
          <div style={{textAlign: 'center'}}>
          <div>
          {/* <img src={thankyou} alt="" /> */}
          <ThankyouAnime />
          </div>
            <div className="Congratulations_img">
              {/* <img src={CongratulationsIcon} /> */}
            </div>
            <div className="main_title">Congratulations</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
