import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StateProvider } from "./Context/StateProvider";
import { initialState, reducer } from "./Context/Reducer";
import Axios from "axios";

Axios.interceptors.request.use(
  function (config) {
    // document.body.classList.add(" ");
    var div1 = document.createElement("div");
    div1.setAttribute("class", "loderBlock");
    div1.setAttribute("id", "saasLoader");
    var div2 = document.createElement("div");
    // div2.setAttribute("class", "pulse");
    div1.appendChild(div2);
    document.body.appendChild(div1);
    return config;
  },
  function (error) {
    // document.body.classList.remove(" ");
    const element = document.getElementById("saasLoader");
    element.remove();
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    // spinning hide
    const element = document.getElementById("saasLoader");
    element.remove();
    // document.body.classList.remove("loading-indicator");
    return response;
  },
  function (error) {
    // document.body.classList.remove("loading-indicator");
    const element = document.getElementById("saasLoader");
    element.remove();

    return Promise.reject(error);
  }
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <StateProvider initialState={initialState} reducer={reducer}>
      <App />
    </StateProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
