import React, { useState, useEffect } from "react";
import Header from "../../Components/Header";
import { useFormik } from "formik";
import { Post } from "../../_common/httpService";
import * as Yup from "yup"
import { Get } from "../../_common/httpService";
import Toast from "../../Components/Toast";
import { toast } from "react-hot-toast";
const Profile = () => {
  const [data, setData] = useState([])
  let subscriptionId = sessionStorage.getItem("subscriptionId")
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  let tenant = sessionStorage.getItem("tenantId")
  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    let tenant = sessionStorage.getItem("tenantId")
    Get(`tenantmaster/profileinfo/${tenant}`)
      .then((res) => {
        setData(res.profileInfo[0])
      })
      .catch((err) => {
      })
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      personalFirstName: data?.personalFirstName,
      personalLastName: data?.personalLastName,
      designation: data?.designation,
      personalEmail: data?.personalEmail,
      countryCode: data?.countryCode,
      mobileNumber: data?.mobileNumber,
    },
    validationSchema: Yup.object({
      personalFirstName: Yup.string()
        .required('Required'),
      personalLastName: Yup.string()
        .required('Required'),
      personalEmail: Yup.string().email("Field should contain a valid e-mail").max(255).required("E-mail is required"),
      designation: Yup.string()
        .required('Required'),
      countryCode: Yup.string()
        .required('Required'),
      mobileNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    }),
    onSubmit: (values) => {
      let tenant = sessionStorage.getItem("tenantId");
      Post(`tenantmaster/saveprofile/${tenant}`, values)
        .then((res) => {
          toast.success("Profile Updated")
        })
        .catch((err) => {
          toast.error(err)
        });
    },
  });
  return (
    <>
      <Toast />
      <div className="fullScreen_bg p0">
        <div className=" ">
          <div className="  pb_24">
            <Header />

            <div className="pagecontentblock">

              <div className="container">
                <div className="row">
                  <div className="col-md-12">

                    <div className=" ">
                      <div className="grayFrame">
                        <div className="grayHeader">

                          <div className="whiteBox mb_24">

                            <h5 className="scatteredShowersText  ">
                              My Profile
                            </h5>

                            <div className=" ">
                              <form onSubmit={formik.handleSubmit}>
                                <div className="row mb_24 ">
                                  <div className="col-md-4 mb_24">
                                    <label
                                      htmlFor="personalFirstName"
                                      className="labeltext"
                                    >
                                      First Name
                                    </label>
                                    <input
                                      type="text"
                                      className="Main_input"
                                      id="personalFirstName"
                                      name="personalFirstName"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.personalFirstName}
                                    />
                                    {formik.touched.personalFirstName &&
                                      formik.errors.personalFirstName ? (
                                      <p className="error_text">
                                        {formik.errors.personalFirstName}
                                      </p>
                                    ) : null}
                                  </div>
                                  <div className="col-md-4 mb_24">
                                    <label htmlFor="personalLastName" className="labeltext">
                                      Last Name
                                    </label>
                                    <input
                                      type="text"
                                      className="Main_input"
                                      id="personalLastName"
                                      name="personalLastName"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.personalLastName}
                                    />
                                    {formik.touched.personalLastName &&
                                      formik.errors.personalLastName ? (
                                      <p className="error_text">
                                        {formik.errors.personalLastName}
                                      </p>
                                    ) : null}
                                  </div>
                                  <div className="col-md-4 mb_24">
                                    <label htmlFor="designation" className="labeltext">
                                      Designation
                                    </label>
                                    <input
                                      type="text"
                                      className="Main_input"
                                      id="designation"
                                      name="designation"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.designation}
                                    />
                                    {formik.touched.designation &&
                                      formik.errors.designation ? (
                                      <p className="error_text">
                                        {formik.errors.designation}
                                      </p>
                                    ) : null}
                                  </div>
                                  <div className="col-md-4">
                                    <label htmlFor="personalEmail" className="labeltext">
                                      Email
                                    </label>
                                    <input
                                      className="Main_input"
                                      type="email"
                                      id="personalEmail"
                                      name="personalEmail"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.personalEmail}
                                    />
                                    {formik.touched.personalEmail &&
                                      formik.errors.personalEmail ? (
                                      <p className="error_text">
                                        {formik.errors.personalEmail}
                                      </p>
                                    ) : null}
                                  </div>
                                  <div className="col-md-4">
                                    <label htmlFor="countryCode" className="labeltext">
                                      Country Code
                                    </label>
                                    <input
                                      type="text"
                                      className="Main_input"
                                      id="countryCode"
                                      name="countryCode"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.countryCode}
                                    />
                                    {formik.touched.countryCode &&
                                      formik.errors.countryCode ? (
                                      <p className="error_text">
                                        {formik.errors.countryCode}
                                      </p>
                                    ) : null}
                                  </div>
                                  <div className="col-md-4">
                                    <label htmlFor="mobileNumber" className="labeltext">
                                      Mobile Number
                                    </label>
                                    <input
                                      type="phone"
                                      className="Main_input"
                                      id="mobileNumber"
                                      name="mobileNumber"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.mobileNumber}
                                    />
                                    {formik.touched.mobileNumber &&
                                      formik.errors.mobileNumber ? (
                                      <p className="error_text">
                                        {formik.errors.mobileNumber}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <div className=" textAlign_center">
                                  <button type="submit" className="Mainbutton width_170px">
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>

                          </div>




                        </div>

                      </div>
                    </div>


                  </div></div></div>



            </div>



          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
