import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { AuthPost, AuthGet, AuthPut } from "../../_common/httpService";
import validateVertical from "../../_common/Validation";
import Stepper from "../../Components/Stepper";
import HomeImprovement from "../../assets/slices/home_improvement.svg";
import SmbLoans from "../../assets/slices/smb_loan.svg";
import PersonalCare from "../../assets/slices/personal_care.svg";
import rightDirection from "../../assets/slices/next_direction.svg";
import AutoLoanIcon from "../../assets/slices/autoLoan_icon.svg";
import BnplLoanIcon from "../../assets/slices/bnplLoan_icon.svg";
import ISALoanIcon from "../../assets/slices/isa icon.svg";
import { useStateValue } from "../../Context/StateProvider";
import Toast from "../../Components/Toast";
import { toast } from "react-hot-toast";
import theecodelogo from "../../assets/images/logo_theecode.svg";
import brandlogo from "../../assets/images/brand_logo.svg";
import UserAvatar from "../../Components/UserAvatar/UserAvatar";

const Vertical = () => {
  const [verticals, setVerticals] = useState([]);
  const [SMB, ISA, BNPL] = ["SMB loan", "ISA", "BNPL"];
  const navigate = useNavigate();
  const tenantId = sessionStorage.getItem("tenantId");
  const [{}, dispatch] = useStateValue();
  const businessName = sessionStorage.getItem("businessName");
  const subscriptionId = sessionStorage.getItem("subscriptionId");
  const verticalID = sessionStorage.getItem("VerticalId");
  const addNewSubscription = JSON.parse(sessionStorage.getItem("newSubscription"));

  const getVerticals = async () => {
    try {
      const res = await AuthGet(addNewSubscription ? `vertical/list/${tenantId}` : "vertical/list/all");
      if (res.statusCode == 200) {
        res.data.tenants.map((verts, ind) => {
          if(verticalID) {
            if(verts?.id == verticalID) {
              setValues({verticalId: verts?.id, businessName: businessName ? businessName : ""});
            }
          }
          setVerticals(res.data.tenants);
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    getVerticals();
  }, []);

  // form- Validation

  // Validate a form using Formik
  const { touched, errors, handleSubmit, values, handleChange, handleBlur, setValues, setErrors } = useFormik({
    initialValues: {
      businessName: businessName ? businessName : "",
      verticalId: verticalID ? verticalID : "",
      verticalName: ""
    },
    enableReinitialize: true,
    validate: validateVertical,
    onSubmit: async (values) => {
      sessionStorage.setItem("businessName", values.businessName);
      if(values.verticalName === SMB
      || values.verticalName === BNPL
      || values.verticalName === ISA
      ){
        sessionStorage.setItem("VerticalId", values.verticalId)
        navigate(`/contactform`)
         return
      }
      
      const subParams = {
        subscriptions: [
          {
            verticalId: values.verticalId,
            tenantId: tenantId,
            businessName: values.businessName,
            subscriptionId: subscriptionId ? subscriptionId : null
          },
        ],
      };

      let data = {
        allTenants: [
          {
            tenantId: tenantId,
            businessName: values.businessName,
          },
        ],
      };
      sessionStorage.setItem("VerticalId", values.verticalId);
      await AuthPost("subscription/create", subParams).then((res) => {
        if (res.statusCode === 200) {
          sessionStorage.setItem("subscriptionId", res.data);
          toast.success(res.message);
          // setSelectedVertical(personalvert);
          AuthPut("tenantmaster/update", data)
            .then(async (res) => {
              if (res.statusCode === 200) {
                AuthGet(`subproduct/list/${values.verticalId}`)
                .then((resp) => {
                  if (resp.statusCode === 200) {
                    sessionStorage.setItem("ProductId", resp.data.subProducts[0].id);
                    sessionStorage.setItem(
                      "selectedVertical",
                      resp.data.subProducts[0].name
                    );

                    const subParams = {
                      subscriptions: [
                        {
                          productId: resp.data.subProducts[0].id,
                          tenantId,
                          verticalId: values.verticalId,
                        },
                      ],
                    };

                    AuthPut(
                      "subscription/update/" +
                        sessionStorage.getItem("subscriptionId"),
                      subParams
                    ).then((respon) => {
                      if(resp.name == "Business loan" && respon.statusCode == 200){
                        navigate ("/change-password");
                      } else if (resp.name != "Business loan" && respon.statusCode === 200) {
                        navigate("/upload-logo");
                      }
                    }).catch((err) => {});
                  }
                }).catch((err) => {});
              }
            })
            .catch((error) => {});
        }
      }).catch((err) => {
        const errObj = JSON.parse(err.message);
        toast.error(errObj.data.message);
      })
    },
  });

  //Get selected vertical
  let updateChange = (e, value) => {
    e?.preventDefault();
    if(value?.is_subscribed) {
      toast.error("Vertical Already Subscribed!");
    } else {
      setValues({verticalId: value?.id, verticalName: value.name, businessName: businessName ? businessName : values.businessName});
    }
  };

  return (
    <div className="fullScreen_bg p0">
      <Toast />
      <div className="topHeader">
        <div className="displayFlex Alignitem_center width_100per">
          <div>
            {" "}
            <div className="logosection headerinherit">
              <div className="displayFlex">
                {/* <img src={menuicon} className="mobileMenuicon" /> */}
                <div className="brand_logo">
                  <img src={brandlogo} className="whiteFilter" />
                </div>
              </div>
            </div>
          </div>

          <div className="width_100per">
            <div>
              {" "}
              <Stepper />
            </div>
          </div>

          <div className="mr_20">
            {
              addNewSubscription === true ? (
                <UserAvatar logout={true} quickGuide={true} business={true} changePassword={true} profile={true} />
              ) : (
              <UserAvatar logout={true} />
              ) 
            }
          </div>
        </div>
      </div>

      <div className="full_header">
        <div className="pagecontentblock">
          <div className="Customerpanel_rectangle border_radius0 box_shadow_none">
            <div className="plr80_ptb40">
              {businessName && !addNewSubscription ? (
                <h3 className="textAlign_center mb_40 HorrorSnob_Color headingTitle28">
                  {businessName}
                </h3>
              ) : (
                <></>
              )}

              <form onSubmit={handleSubmit}>
                  {addNewSubscription ? (
                <div className="row mb_24">
                    <div className="col-md-12">
                      <div>
                        {/* <label htmlFor="business_name">Business Name</label> */}
                        <h6 className="mb_24 textAlign_center ThunderboltBlue_Color headingTitle22">
                          Business Name
                        </h6>
                        <div>
                          <input
                            type="text"
                            className="form_control"
                            id="business_name"
                            name="businessName"
                            placeholder="Enter your business name"
                            onChange={handleChange}
                            value={values.businessName}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      {errors.businessName && touched.businessName ? (
                        <div className="error_text">{errors.businessName}</div>
                      ) : null}
                    </div>
                    <div className="col-md-6"></div>
                    </div>
                  ) : (
                    <></>
                  )}

                <h5 className="textAlign_center mb_40 ThunderboltBlue_Color">
                  Choose a option below to know more about you
                  <p className="lowWeight_text">
                    This will help us to give you a tailored experience
                  </p>
                </h5>

                {errors.verticalId && touched.verticalId ? (
                  <div className="error_text">{errors.verticalId}</div>
                ) : null}
                <label>
                  <div className="row">
                    {/* <div className="col-md-4">
                      <label
                        htmlFor="verticalId"
                        className={`vertical_item_noHover mediaObject ${verticalIndex === 5 ? `seleted` : ""}`}
                        onClick={(e) => updateChange(e, personalvert, 5)}>
                        <div className="mr_20">
                          <img src={PersonalCare} alt={"Vertical_Icon"} />

                        </div>
                        <div className="displayFlex justifyContent_spaceBetween Alignitem_center width_100per">
                          <div className="mediaObject_body">
                            <input
                              type="radio"
                              name="verticalId"
                              value={personalvert?.id}
                              style={{ display: "none" }}
                            />
                            <h6>{personalvert?.name}</h6>
                            <p className="mb_0">{personalvert?.description}</p>
                          </div>
                        </div>

                      </label>


                    </div> */}
                    {verticals?.map((vertical, i) => {
                      return (
                        // (vertical?.is_subscribed ? vertical?.is_subscribed === false : true) && (
                          <div className="col-md-4 mb_24" key={i}
                            style={vertical?.is_subscribed ? {opacity: .5} : {opacity: 1}}
                          >
                            <label
                              htmlFor="verticalId"
                              className={
                                vertical.name === "Auto Loans" ||
                                vertical.name === "Personal care" ||
                                vertical.name === "SMB loan" ||
                                vertical.name === "BNPL" ||
                                vertical.name === "ISA" ||
                                vertical.name === "Home improvement"
                                  ? `vertical_item_noHover mediaObject ${
                                      values.verticalId === vertical.id
                                        ? `seleted`
                                        : ""
                                    }`
                                  : `vertical_item mediaObject ${
                                      values.verticalId === vertical.id
                                        ? `seleted`
                                        : ""
                                    }`
                              }
                              onClick={(e) => updateChange(e, vertical)}
                            >
                              <div className="mr_20">
                                {vertical.name === "Personal care" ? (
                                  <img
                                    src={PersonalCare}
                                    alt={"Vertical_Icon"}
                                  />
                                ) : vertical.name === "SMB loan" ? (
                                  <img src={SmbLoans} alt={"Vertical_Icon"} />
                                ) : vertical.name === "Home improvement" ? (
                                  <img
                                    src={HomeImprovement}
                                    alt={"Vertical_Icon"}
                                  />
                                ) : vertical.name === "Auto Loans" ? (
                                  <img
                                    src={AutoLoanIcon}
                                    alt={"Vertical_Icon"}
                                  />
                                ) : vertical.name === "BNPL" ? (
                                  <img
                                    src={BnplLoanIcon}
                                    alt={"Vertical_Icon"}
                                  />
                                ) : (
                                  <img
                                    src={ISALoanIcon}
                                    alt={"Vertical_Icon"}
                                  />
                                )}
                              </div>
                              <div className="displayFlex justifyContent_spaceBetween Alignitem_center width_100per">
                                <div className="mediaObject_body">
                                  <input
                                    type="radio"
                                    name="verticalId"
                                    value={values.verticalId}
                                    style={{ display: "none" }}
                                    onBlur={handleBlur}
                                  />
                                   <h6>{vertical?.name === "Personal care" ? "Health Care" : vertical?.name}</h6>
                                  <p className="mb_0">
                                    {vertical?.description}
                                  </p>
                                </div>
                                <div>
                                  <img src={rightDirection} />
                                </div>
                              </div>
                            </label>
                          </div>
                        // )
                      );
                    })}
                  </div>
                  {/* <h5>{selectedVertical?.name}</h5> */}

                  {/* </div> */}
                </label>

                <div className="textAlign_center">
                  <button type="submit" className="Mainbutton  ">
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vertical;
