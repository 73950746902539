import React from "react";
import EditIcon from "../../../../assets/slices/edit_icon.svg";

const EmailTemplate = () => {
  return (
    <>

      <div className="container-fluid">
        <div className="row p0">
          <div className="col-md-12">
            <div className="whiteboxblock100per">

              <h3 className="  mb_40  sectiontitle  ">
                Finance Documents
              </h3>

              <div className="Table_Wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Document</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Signup Email</td>
                      <td>
                        <button
                          className="emptyBtn"
                          title="Edit"
                        >
                          <img src={EditIcon} />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Reset Password</td>
                      <td>
                        <button
                          className="emptyBtn"
                          title="Edit"
                        >
                          <img src={EditIcon} />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Email Verify</td>
                      <td>
                        <button
                          className="emptyBtn"
                          title="Edit"
                        >
                          <img src={EditIcon} />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Connect Bank</td>
                      <td>
                        <button
                          className="emptyBtn"
                          title="Edit"
                        >
                          <img src={EditIcon} />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Sign Contract</td>
                      <td>
                        <button
                          className="emptyBtn"
                          title="Edit"
                        >
                          <img src={EditIcon} />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Application Denial</td>
                      <td>
                        <button
                          className="emptyBtn"
                          title="Edit"
                        >
                          <img src={EditIcon} />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Comments</td>
                      <td>
                        <button
                          className="emptyBtn"
                          title="Edit"
                        >
                          <img src={EditIcon} />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Add Practice</td>
                      <td>
                        <button
                          className="emptyBtn"
                          title="Edit"
                        >
                          <img src={EditIcon} />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>Add Adminuser</td>
                      <td>
                        <button
                          className="emptyBtn"
                          title="Edit"
                        >
                          <img src={EditIcon} />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>


            </div>



          </div>

        </div>



      </div>





































    </>
  )
};

export default EmailTemplate;
