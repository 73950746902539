import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Stepper from "../../Components/Stepper";
import Toast from "../../Components/Toast";
import { toast } from "react-hot-toast";
import { Formik, useFormik } from "formik";
import { validateOtp } from "../../_common/Validation";
import { Get, Post } from "../../_common/httpService";
import * as Yup from "yup";
import theecodelogo from "../../assets/images/logo_theecode.svg";
import brandlogo from "../../assets/images/brand_logo.svg";
import UserAvatar from "../../Components/UserAvatar/UserAvatar";

export const VerifyOtp = () => {
  const navigate = useNavigate();
  const tenantId = sessionStorage.getItem("tenantId");
  const addNewSubscription = JSON.parse(sessionStorage.getItem("newSubscription"));
  const [timer, setTimer] = useState(180);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [timer]);


  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const formikOtpVerify = useFormik({
    initialValues: {
      phoneotp: "",
      emailotp: "",
    },

    validate: validateOtp,
    // validationSchema: validateOtp,
    // enableReinitialize: true,

    onSubmit: async (values) => {

      const resp = await Get(`tenant/tenant-emailphone/${tenantId}`);
      if(resp.statusCode === 200) {
        values.email = resp?.data?.email;
        values.subscriptionId = sessionStorage.getItem("subscriptionId");
        await Post("tenant/otp-verify", values)
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success("Success");
            if(addNewSubscription) {
              sessionStorage.removeItem("newSubscription");
            }
            setTimeout(() => navigate("/congratulations"), 2000);
            formikOtpVerify.resetForm();
          }
        })
        .catch((err) => {
          const errObj = JSON.parse(err.message);
          toast.error(errObj.data.message);
        });
      }
    },
  });

  const handleResendOTP = async () => {
    try {
      formikOtpVerify.resetForm();
      let resp = await Post(`tenant/generate-otp/${tenantId}`);
      if(resp.statusCode === 200) {
        toast.success("OTP resent to phone and email");
      }
    } catch (error) {
      console.log('error::: ', error);
    }
  }

  return (
    <div className="fullScreen_bg p0">
      <Toast />

      <div className="topHeader">
        <div className="displayFlex Alignitem_center width_100per">
          <div>
            {" "}
            <div className="logosection headerinherit">
              <div className="displayFlex">
                {/* <img src={menuicon} className="mobileMenuicon" /> */}
                <div className="brand_logo">
                  <img src={brandlogo} className="whiteFilter" />
                </div>
              </div>
            </div>
          </div>

          <div className="width_100per">
            <div>
              {" "}
              <Stepper />
            </div>
          </div>

          <div className="mr_20">
            {
              addNewSubscription === true ? (
                <UserAvatar logout={true} quickGuide={true} business={true} changePassword={true} profile={true} />
              ) : (
                <UserAvatar logout={true} />
              ) 
            }
          </div>
        </div>
      </div>

      <div className="full_header">
        <div className=" ">
          <div className="pagecontentblock">
            <div className=" ">
              <form className="otpblock mt_20">
                <h3 className="     sectiontitle  mb_30">OTP Verification</h3>
                <div className="row justifyContent_center p0 ">
                  <div className="form-group col-md-12 col-xs-12 col-sm-6 mb30px">
                    <div className="float-label">
                      <label htmlFor="phoneotp">Enter Mobile OTP</label>
                      <input
                        placeholder="Enter Mobile OTP"
                        id="phoneotp"
                        className="adminInput border_bottomgrey"
                        name="phoneotp"
                        onChange={(e) => {
                          formikOtpVerify.setFieldValue(
                            "phoneotp",
                            e.target.value
                            // providernameValidation(e.target.value)
                          );
                        }}
                        onBlur={formikOtpVerify.handleBlur}
                        value={formikOtpVerify.values.phoneotp}
                      />
                    </div>

                    {formikOtpVerify.touched.phoneotp &&
                    formikOtpVerify.errors.phoneotp ? (
                      <p className="error_text text-danger">
                        {formikOtpVerify.errors.phoneotp}
                      </p>
                    ) : null}
                  </div>

                  <div className="form-group col-md-12 col-xs-12 col-sm-6  ">
                    <div className="float-label">
                      <label htmlFor="emailotp">Enter Email OTP</label>
                      <input
                        placeholder="Enter Email OTP"
                        id="emailotp"
                        className="adminInput border_bottomgrey"
                        name="emailotp"
                        onChange={(e) => {
                          formikOtpVerify.setFieldValue(
                            "emailotp",
                            e.target.value
                            // providernameValidation(e.target.value)
                          );
                        }}
                        onBlur={formikOtpVerify.handleBlur}
                        value={formikOtpVerify.values.emailotp}
                      />
                    </div>
                    {formikOtpVerify.touched.emailotp &&
                    formikOtpVerify.errors.emailotp ? (
                      <p className="error_text text-danger">
                        {formikOtpVerify.errors.emailotp}
                      </p>
                    ) : null}
                    <div className="resend-otp">
                      {timer > 0 ? (
                        <span>
                          Resend OTP in {minutes.toString().padStart(2, "0")}:
                          {seconds.toString().padStart(2, "0")}
                        </span>
                      ) : (
                        <span onClick={handleResendOTP} className="blueWithUnderscore">
                          Resend OTP
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="textAlign_center mt_20">
                  <button
                    className="Mainbutton"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      formikOtpVerify.submitForm();
                    }}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
