import React, { useState, useEffect } from "react"
import Header from "../../Components/Header"
import { useFormik } from "formik";
import * as Yup from "yup"
import { Post } from "../../_common/httpService";
import { Get } from "../../_common/httpService";
import Toast from "../../Components/Toast";
import { toast } from "react-hot-toast";
const BusinessInfo = () => {
  const [data, setData] = useState([])
  let subscriptionId = sessionStorage.getItem("subscriptionId")
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  useEffect(() => {
    getData()
  }, [])
  const getData = () => {
    let tenant = sessionStorage.getItem("tenantId")
    Get(`tenantmaster/businessinfo/${tenant}`)
      .then((res) => {
        setData(res.businessInfo)
      })
      .catch((err) => {
      })
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: data?.firstName,
      lastName: data?.lastName,
      businessName: data?.businessName,
      businessEmail: data?.businessEmail,
      businessWebsite: data?.businessWebsite,
      businessAddress: data?.businessAddress,
      businessCity: data?.businessCity,
      businessState: data?.businessState,
      businessCountry: data?.businessCountry,
      businessContact: data?.businessContact,
      taxId: data?.taxId
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('Required').nullable(),
      lastName: Yup.string()
        .required('Required').nullable(),
      businessName: Yup.string()
        .required('Required').nullable(),
      businessEmail: Yup.string().email("Field should contain a valid e-mail").max(255).required("E-mail is required").nullable(),
      businessWebsite: Yup.string()
        .required('Required').nullable(),
      businessAddress: Yup.string()
        .required('Required').nullable(),
      businessCity: Yup.string()
        .required('Required').nullable(),
      businessState: Yup.string()
        .required('Required').nullable(),
      businessCountry: Yup.string()
        .required('Required').nullable(),
      businessContact: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required').nullable(),
      taxId: Yup.string()
        .required('Required').nullable(),
    }),
    onSubmit: (values) => {
      let tenant = sessionStorage.getItem('tenantId')
      Post(`tenantmaster/create/${tenant}`, values)
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success('Business Information Submitted')
          }
        })
        .catch((err) => {
          toast.error(err)
        })
    }
  })
  return (
    <>
      <Toast />
      <div className="fullScreen_bg p0">
        <div className=" ">




          <div className="  pb_24">
            <Header />


            <div className="pagecontentblock">

              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className=" ">
                      <div className="grayFrame">
                        <div className="grayHeader">
                          <h2 className="headingTitle24 HorrorSnob_Color">
                            Please update your KYC/KYB form.
                          </h2>
                          <p className="paracontentText">
                            This will help us continue your access to your free
                            account
                          </p>
                        </div>
                        <div className="grayContent">
                          <form onSubmit={formik.handleSubmit}>


                            <div className="whiteBox mb_24">

                              <h5 className="scatteredShowersText  ">
                                Business Details
                              </h5>
                              <div className="row  ">
                                <div className="col-md-4 mb_24">
                                  <label htmlFor="firstName" className="labeltext">
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    className="Main_input"
                                    id="firstName"
                                    name="firstName"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstName}
                                  />
                                  {formik.touched.firstName &&
                                    formik.errors.firstName ? (
                                    <p className="error_text">
                                      {formik.errors.firstName}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-4 mb_24">
                                  <label htmlFor="lastName" className="labeltext">
                                    Last name
                                  </label>
                                  <input
                                    type="text"
                                    className="Main_input"
                                    id="lastName"
                                    name="lastName"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName}
                                  />
                                  {formik.touched.lastName && formik.errors.lastName ? (
                                    <p className="error_text">
                                      {formik.errors.lastName}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-4 mb_24">
                                  <label htmlFor="businessName" className="labeltext">
                                    Business Name
                                  </label>
                                  <input
                                    type="text"
                                    className="Main_input"
                                    id="businessName"
                                    name="businessName"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.businessName}
                                  />
                                  {formik.touched.businessName &&
                                    formik.errors.businessName ? (
                                    <p className="error_text">
                                      {formik.errors.businessName}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-4 mb_24">
                                  <label htmlFor="businessEmail" className="labeltext">
                                    Business Email
                                  </label>
                                  <input
                                    type="text"
                                    className="Main_input"
                                    id="businessEmail"
                                    name="businessEmail"
                                    onChange={formik.handleChange}
                                    value={formik.values.businessEmail}
                                  />
                                  {formik.touched.businessEmail &&
                                    formik.errors.businessEmail ? (
                                    <p className="error_text">
                                      {formik.errors.businessEmail}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-4">
                                  <label
                                    htmlFor="businessWebsite"
                                    className="labeltext"
                                  >
                                    Business Website
                                  </label>
                                  <input
                                    type="text"
                                    className="Main_input"
                                    id="businessWebsite"
                                    name="businessWebsite"
                                    onChange={formik.handleChange}
                                    value={formik.values.businessWebsite}
                                  />
                                  {formik.touched.businessWebsite &&
                                    formik.errors.businessWebsite ? (
                                    <p className="error_text">
                                      {formik.errors.businessWebsite}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                            </div>

                            <div className="whiteBox mb_24">
                              <h5 className="scatteredShowersText  ">
                                Address Details
                              </h5>
                              <div className="row  ">
                                <div className="col-md-4 mb_24">
                                  <label
                                    htmlFor="businessAddress"
                                    className="labeltext"
                                  >
                                    Street Address
                                  </label>
                                  <input
                                    type="text"
                                    className="Main_input"
                                    id="businessAddress"
                                    name="businessAddress"
                                    onChange={formik.handleChange}
                                    value={formik.values.businessAddress}
                                  />
                                  {formik.touched.businessAddress &&
                                    formik.errors.businessAddress ? (
                                    <p className="error_text">
                                      {formik.errors.businessAddress}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-4 mb_24">
                                  <label htmlFor="businessCity" className="labeltext">
                                    Business City
                                  </label>
                                  <input
                                    type="text"
                                    className="Main_input"
                                    id="businessCity"
                                    name="businessCity"
                                    onChange={formik.handleChange}
                                    value={formik.values.businessCity}
                                  />
                                  {formik.touched.businessCity &&
                                    formik.errors.businessCity ? (
                                    <p className="error_text">
                                      {formik.errors.businessCity}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-4 mb_24">
                                  <label htmlFor="firstName" className="labeltext">
                                    Business State
                                  </label>
                                  <input
                                    type="text"
                                    className="Main_input"
                                    id="businessState"
                                    name="businessState"
                                    onChange={formik.handleChange}
                                    value={formik.values.businessState}
                                  />
                                  {formik.touched.businessState &&
                                    formik.errors.businessState ? (
                                    <p className="error_text">
                                      {formik.errors.businessState}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-4 mb_24">
                                  <label
                                    htmlFor="businessCountry"
                                    className="labeltext"
                                  >
                                    Business Country
                                  </label>
                                  <input
                                    type="text"
                                    className="Main_input"
                                    id="businessCountry"
                                    name="businessCountry"
                                    onChange={formik.handleChange}
                                    value={formik.values.businessCountry}
                                  />
                                  {formik.touched.businessCountry &&
                                    formik.errors.businessCountry ? (
                                    <p className="error_text">
                                      {formik.errors.businessCountry}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-4 mb_24">
                                  <label
                                    htmlFor="businessContact"
                                    className="labeltext"
                                  >
                                    Contact Number
                                  </label>
                                  <input
                                    type="text"
                                    className="Main_input"
                                    id="businessContact"
                                    name="businessContact"
                                    onChange={formik.handleChange}
                                    value={formik.values.businessContact}
                                  />
                                  {formik.touched.businessContact &&
                                    formik.errors.businessContact ? (
                                    <p className="error_text">
                                      {formik.errors.businessContact}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-md-4 mb_24">
                                  <label htmlFor="taxId" className="labeltext">
                                    Tax ID
                                  </label>
                                  <input
                                    type="text"
                                    className="Main_input"
                                    id="taxId"
                                    name="taxId"
                                    onChange={formik.handleChange}
                                    value={formik.values.taxId}
                                  />
                                  {formik.touched.taxId && formik.errors.taxId ? (
                                    <p className="error_text">{formik.errors.taxId}</p>
                                  ) : null}
                                </div>
                              </div>

                            </div>


                            <div className=" textAlign_center">
                              <button
                                type="submit"
                                className="Mainbutton width_170px"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div></div></div>

            </div>


          </div>



        </div>
      </div>
    </>
  );
}

export default BusinessInfo
