import React from "react";
import FlowChart from "./assets/how-your-lending-app-works.svg";

const LendingApp = () => {
  return (
    <div style={{ backgroundColor: "#fff" }} className="body">
      <div className="help_heading mb_24">How your Lending app works</div>
      <div className="displayFlex Alignitem_center justifyContent_center flexDirection pb_24">
        <img src={FlowChart} alt="how-your-lending-app-works" />
      </div>
    </div>
  );
};

export default LendingApp;
