import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../Components/Toast";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import "react-phone-number-input/style.css";
import brandlogo from "../../assets/images/brand_logo.svg";
import PhoneInput from "react-phone-number-input";
import { validationContractfrom } from "../../_common/Validation";
import { Post } from "../../_common/httpService";
import { json1hr } from "./Json1hour";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { countryDialcodes, timezone } from "../../_common/common";
import UserAvatar from "../../Components/UserAvatar/UserAvatar";

const Contractfrom = () => {
  const navigate = useNavigate();
  const  verticalID = sessionStorage.getItem("VerticalId")
  const [email, setEmail] = useState("");
  const [countryList] = useState(countryDialcodes);
  const [countryCode, setCountryCode] = useState("US");
  const [dateErr, setDateErr] = useState({err: false, touched: false, errtxt: ''});
  const [dateVal, setDateVal] = useState(null);
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate()+1)
  const addNewSubscription = JSON.parse(sessionStorage.getItem("newSubscription"));

  useEffect(() => {
    const email = sessionStorage.getItem("email");
    setEmail(email);
  }, []);

  const formikSignup = useFormik({
    initialValues: {
      companyName:  "",
      name: "",
      email:sessionStorage.getItem("email")
      ? sessionStorage.getItem("email")
      :  "",
      phone: sessionStorage.getItem("phoneNumber")
      ? sessionStorage.getItem("phoneNumber")
      : "",
      country: sessionStorage.getItem("country")
      ? sessionStorage.getItem("country")
      : "",
      comments: "",
      timezone: "",
      hour: "",
      calendar: null,
    },

    validate: validationContractfrom,
    // enableReinitialize: true,

    onSubmit: async (values) => {
      if(dateErr.err) {
        return
      }
      values.calendar = dateVal;
      values.verticalid = verticalID
      await Post("tenant/save", values)
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success(res?.message);
            navigate("/thankpage");
            formikSignup.resetForm();
          } else {
            toast.error(res.message[0]);
          }
        })
        .catch((err) => {
          console.log("Err::", err);
        });
    },
  });

  const handleDOB = (val) => {
    if(val?.$d != "Invalid date" && val?.$d) {
      setDateVal(val?.$d)
    }

    if(val?.$d == "Invalid Date") {
      setDateErr({...dateErr, err: true, touched: true, errtxt: "Invalid date"});
    } else if (!val?.$d) {
      setDateErr({...dateErr, err: true, touched: true, errtxt: "Date is required"});
      setDateVal("");
    }
  };

  return (
    <div className="fullScreen_bg p0">
      <Toast />
      <div className="topHeader">
        <div className="displayFlex justifyContent_spaceBetween Alignitem_center width_100per">
          <div>
            {" "}
            <div className="logosection headerinherit">
              <div className="displayFlex">
                {/* <img src={menuicon} className="mobileMenuicon" /> */}
                <div className="brand_logo">
                  <img src={brandlogo} className="whiteFilter" />
                </div>
              </div>
            </div>
          </div>

          <div className="mr_20">
          {
            addNewSubscription === true ? (
              <UserAvatar logout={true} quickGuide={true} business={true} changePassword={true} profile={true} />
            ) : (
              <UserAvatar logout={true} />
            ) 
          }
          </div>
        </div>
      </div>

      <div className="full_header">
        <div className=" ">
          <div className="pagecontentblock">
            <div className="whiteboxblock">
              <h3 className="     sectiontitle  ">Schedule a Demo</h3>

              <div className=" ">
                <form onSubmit={formikSignup.handleSubmit}>
                  <div className="row">
                    <div className="form-group col-md-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="companyName">
                          Organization <span className="star_red">*</span>
                        </label>
                        <input
                          placeholder="Enter Organization"
                          id="companyName"
                          className="adminInput border_bottomgrey"
                          name="companyName"
                          onChange={(e) => {
                            formikSignup.setFieldValue(
                              "companyName",
                              e.target.value
                                .replace(/[^a-z ]/gi, "")
                                .replace(/^[ ]/, "")
                            );
                          }}
                          onBlur={formikSignup.handleBlur}
                          value={formikSignup.values.companyName}
                        />
                      </div>

                      {formikSignup.touched.companyName &&
                      formikSignup.errors.companyName ? (
                        <p className="error_text text-danger">
                          {formikSignup.errors.companyName}
                        </p>
                      ) : null}
                    </div>

                    <div className="form-group col-md-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="name">
                          Name <span className="star_red">*</span>
                        </label>
                        <input
                          placeholder="Enter Name"
                          id="name"
                          className="adminInput border_bottomgrey"
                          name="name"
                          onChange={(e) => {
                            formikSignup.setFieldValue(
                              "name",
                              e.target.value
                                .replace(/[^a-z0-9 ]/gi, "")
                                .replace(/^[ ]/, "")
                              // .replace(/[^a-zA-Z ]/g, "")
                              // providernameValidation(e.target.value)
                            );
                          }}
                          onBlur={formikSignup.handleBlur}
                          value={formikSignup.values.name}
                        />
                      </div>

                      {formikSignup.touched.name && formikSignup.errors.name ? (
                        <p className="error_text text-danger">
                          {formikSignup.errors.name}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="email">
                          Email <span className="star_red">*</span>
                        </label>
                        <input
                          placeholder="Enter Email"
                          id="email"
                          name="email"
                          className="adminInput border_bottomgrey"
                          readOnly
                          onChange={(e) => {
                            formikSignup.setFieldValue(
                              "email",
                              e.target.value
                              // emailValidation(e.target.value)
                            );
                          }}
                          onBlur={formikSignup.handleBlur}
                          value={formikSignup.values.email}
                        />
                      </div>
                      {formikSignup.touched.email &&
                      formikSignup.errors.email ? (
                        <p className="error_text text-danger">
                          {formikSignup.errors.email}
                        </p>
                      ) : null}
                    </div>

                    <div className="form-group col-sm-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="mobileNumber">
                          Country <span className="star_red">*</span>
                        </label>
                        <select
                          className="adminInput border_bottomgrey"
                          id="country"
                          name="country"
                          onChange={(e) => {
                            formikSignup.setFieldValue(
                              "country",
                              e.target.value
                            );
                            setCountryCode(e.target.value);
                            formikSignup.setFieldValue("phone", "");
                          }}
                          onBlur={formikSignup.handleBlur}
                          value={formikSignup?.values?.country}
                        >
                          {countryList?.map((el, i) => (
                            <option
                              key={i}
                              className="border_bottomgrey"
                              value={el?.code}
                            >
                              {el?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {formikSignup?.touched?.country &&
                      formikSignup?.errors?.country ? (
                        <p className="error_text text-danger">
                          {formikSignup.errors.country}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="mobileNumber">
                          Phone Number <span className="star_red">*</span>
                        </label>
                        <PhoneInput
                          className="mobileInput border_bottomgrey"
                          defaultCountry={countryCode}
                          international
                          type="text"
                          // readOnly
                          id="mobileNumber"
                          // mask="000-00-0000"
                          placeholder="Phone Number"
                          onChange={(e) => {
                            console.log("Event::", e);
                            formikSignup.setFieldValue("phone", e);
                          }}
                          name="phone"
                          onBlur={formikSignup?.handleBlur}
                          value={formikSignup?.values?.phone}
                        />
                      </div>
                      {formikSignup?.touched?.phone &&
                      formikSignup?.errors?.phone ? (
                        <p className="error_text text-danger">
                          {formikSignup.errors.phone}
                        </p>
                      ) : null}
                    </div>

                    <div className="form-group col-sm-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="calendar">
                          Date <span className="star_red">*</span>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="customDatapicker width_100per"
                            value={dateVal}
                            onChange={(newValue) => handleDOB(newValue)}
                            onError={(err) => {
                              err !== null ? setDateErr({err: true, touched: true, errtxt: "Invalid date"}) : setDateErr({err: false, touched: false, errtxt: err})
                            }}
                            minDate={tomorrow}
                            disableHighlightToday={true}
                            renderInput={(params) => 
                              <TextField name="calender" onBlur={() => !dateVal && handleDOB()} {...params} />
                            }
                          />
                        </LocalizationProvider> 
                      </div>
                      {dateErr.err &&
                      dateErr.touched ? (
                        <p className="error_text text-danger">
                          {dateErr.errtxt}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row mb_24">
                    <div className="form-group col-sm-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="timezone">
                          Time Zone <span className="star_red">*</span>
                        </label>
                        <select 
                          className="adminInput border_bottomgrey"
                          id="timezone"
                          name="timezone"
                          onChange={(e) => {
                            formikSignup.setFieldValue(
                              "timezone",
                              e.target.value
                            );
                          }}
                          onBlur={formikSignup.handleBlur}
                          value={formikSignup?.values?.timezone}
                        >
                          <option value="">Select Timezone</option>
                          {timezone?.map((el, i) => (
                            <option
                              key={i}
                              className="border_bottomgrey"
                              value={el?.code}
                            >
                              {el?.text}
                            </option>
                          ))}
                        </select>
                      </div>
                      {formikSignup?.touched?.timezone &&
                      formikSignup?.errors?.timezone ? (
                        <p className="error_text text-danger">
                          {formikSignup.errors.timezone}
                        </p>
                      ) : null}
                    </div>
                    <div className="form-group col-sm-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="hour">
                          Hour Slots <span className="star_red">*</span>
                        </label>
                        <select
                          className="adminInput border_bottomgrey"
                          id="hour"
                          name="hour"
                          onChange={(e) => {
                            formikSignup.setFieldValue("hour", e.target.value);
                          }}
                          onBlur={formikSignup.handleBlur}
                          value={formikSignup?.values?.hour}
                        >
                          <option value="">Select Hour Slots</option>
                          
                          {json1hr.map((el, i) => (
                            <option
                              key={i}
                              className="border_bottomgrey"
                              value={el?.code}
                            >
                              {el?.time}
                            </option>
                          ))}
                        </select>
                      </div>
                      {formikSignup?.touched?.hour &&
                      formikSignup?.errors?.hour ? (
                        <p className="error_text text-danger">
                          {formikSignup.errors.hour}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  {/* <div className="row"> */}
                  {/* <div className="form-group col-md-6 col-xs-12 col-sm-6"> */}
                  <div className="float-label">
                    <label htmlFor="commentss">
                      Comments <span className="star_red">*</span>
                    </label>
                    <textarea
                      rows={4}
                      placeholder="Enter Comments"
                      id="comments"
                      className="textareaField"
                      name="comments"
                      onChange={(e) => {
                        formikSignup.setFieldValue(
                          "comments",
                          e.target.value
                            .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'')
                        );
                      }}
                      onBlur={formikSignup.handleBlur}
                      value={formikSignup.values.comments}
                    />
                  </div>

                  {formikSignup.touched.comments &&
                  formikSignup.errors.comments ? (
                    <p className="error_text text-danger">
                      {formikSignup.errors.comments}
                    </p>
                  ) : null}
                  {/* </div> */}

                  {/* </div> */}

                  <div className="textAlign_center mt_20">
                    <button
                      className="Mainbutton"
                      type="submit"
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   console.log("Clicked!!!");
                      //   formikSignup.submitForm();
                      // }}
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contractfrom;