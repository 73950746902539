import "./App.css";
import "./assets/css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Register from "./Pages/Register/Register";
import Login from "./Pages/Login/Login";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import TenantDashboard from "./Pages/TenantDashboard/TenantDashboard";
import Vertical from "./Pages/Vertical/Vertical";
import "react-toastify/dist/ReactToastify.css";
import Product from "./Pages/Product/Product";
import UploadLogo from "./Pages/LogoUpload/UploadLogo";
import Theme from "./Pages/Theme/Theme";
import BusinessInfo from "./Pages/BusinessInformation/BusinessInfo";
import Profile from "./Pages/Profile/Profile";
import Thankyou from "./Pages/Congratulation/Thankyou";
import ControlPanel from "./Pages/Control-Panel/ControlPanel";
import { useRoutes } from "react-router-dom";
import Dashboard from "./Pages/Control-Panel/Pages/Dashboard/Dashboard";
import TextEditor from "./Pages/Control-Panel/Components/Editor";
import EmailVerify from "./Pages/EmailVerify/EmailVerify";
import TenantGuard from "./Components/RouterGuard";
import ChangePassword from "./Pages/Change-Password/ChangePassword";
import TryNow from "./Pages/TryNow/TryNow";
import Getstarted from "./Pages/GetStarted/GetStarted";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import { SignUp } from "./Pages/Sign-Up/SignUp";
import { VerifyOtp } from "./Pages/VerifyOtp/VerifyOtp";
import { TenantLanding } from "./Pages/Tenant-Landing/TenantLanding";
import GettingStarted from "./Pages/Quick-Guide/GettingStarted";
import LendingApp from "./Pages/Quick-Guide/HowLendingAppWorks/HowLendingAppWorks";
import HomeLanding, { updateAvgLoansData, updateLoanDisbursedData, updatePieChartsData } from "./Pages/Landing-Home/HomeLanding";
import Contactfrom from "./Pages/Contactfrom/Contactform";
import { ThanksPage } from "./Pages/Contactfrom/ThanksPage";
import HowToAddServiceProviders from "./Pages/Quick-Guide/HowToAddServiceProviders/HowToAddServiceProviders";
import HowToInviteBorrower from "./Pages/Quick-Guide/HowToInviteBorrower/HowToInviteBorrower";
import BrandPage from "./Pages/Quick-Guide/BrandingPage/BrandPage";
import { useEffect } from "react";
import { io } from 'socket.io-client';
import { useStateValue } from "./Context/StateProvider";

function App() {
  let element = useRoutes([
    { path: "/", element: <Login /> },
    { path: "*", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/verify/:id", element: <EmailVerify /> },
    { path: "/getstarted", element: <Getstarted /> },
    { path: "password-reset", element: <ResetPassword /> },
    {
      element: <TenantGuard />,
      children: [
        {
          path: "/docs",
          element: <TenantLanding />,
          children: [
            { path: "/docs/home", element: <HomeLanding/> },
            { path: "/docs/getting-started", element: <GettingStarted /> },
            { path: "/docs/lending-app", element: <LendingApp /> },
            { path: "/docs/how-to-add-service-providers", element: <HowToAddServiceProviders /> },
            { path: "/docs/how-to-invite-borrower", element: <HowToInviteBorrower /> },
            { path: "/docs/brand-page", element: <BrandPage /> },
          ],
        },

        { path: "/change-password", element: <ChangePassword /> },
        { path: "/product/:id", element: <Product /> },
        { path: "/signup", element: <SignUp /> },
        { path: "/vertical", element: <Vertical /> },
        { path: "/upload-logo", element: <UploadLogo /> },
        { path: "/theme", element: <Theme /> },
        { path: "/verify-otp", element: <VerifyOtp /> },
        { path: "/congratulations", element: <Thankyou /> },
        { path: "/businessinfo/:id", element: <BusinessInfo /> },
        { path: "/tenant-dashboard/:id", element: <TenantDashboard /> },
        { path: "/profile/:id", element: <Profile /> },
        { path: "/contactform", element:<Contactfrom/> },
        { path: "/thankpage", element:<ThanksPage/> }
      ],
    },
    {
      path: "/control-panel",
      element: <ControlPanel />,
      children: [
        { path: "dashboard/:id/:token", element: <Dashboard /> },
        {
          path: "editdocument/:filename",
          element: <TextEditor />,
        },
      ],
    },
  ]);

  const tenantId = sessionStorage.getItem('tenantId');
  const [{socketObj}, dispatch] = useStateValue();

  // SOCKET
  useEffect(() => {
    if(tenantId) {
      const socket = io(process.env.REACT_APP_API_URL, {
        query:`tenant_id=${tenantId}`
      });
      socket.on('chartData', (data) => {
        updatePieChartsData(data?.dashboardData, dispatch);
        updateAvgLoansData(data?.avgLoanAmountByVertical, dispatch)
        updateLoanDisbursedData(data?.noOfLoansDisbursedByMonth, dispatch);
      });
      dispatch({
        type: 'SET_SOCKET_OBJ',
        payload: socket
      })
    } else {
       if (socketObj) {
        socketObj.emit("logout", "");
        dispatch({
          type: 'SET_SOCKET_OBJ',
          payload: null
        })
       }
    }
  }, [tenantId])

  return element;
}

export default App;
