import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";

Chart.register(CategoryScale);

// data props array example model: [{name: 'string', amount: 0}]
const BarChart = ({ data, hoverOffset, currencySymbol="" }) => {
  let barChartData = {
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: [
          "#672c2c",
          "#eedcdc",
          "#e4d7d7",
          "#ff575a",
          "#ad57a8",
          "#76c46c",
        ],
        hoverOffset,
        barThickness: 100,
        maxBarThickness: 100,
        minBarLength: 1
      },
    ],
     
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${currencySymbol} ` + context.parsed.y;
          },
        },
      },
    },
  };
  
  data?.map((ele) => {
    barChartData.labels.push(ele.name);
    barChartData.datasets[0].data.push(Number(ele.amount));
  });
  
  return <Bar data={barChartData} options={options} />;
};

export default BarChart;
