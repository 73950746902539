import React from "react";
import InviteBorrower from './assets/how-to-invite-borrower.png'

const HowToInviteBorrower = () => {
  return (
    <div style={{backgroundColor: '#fff'}} className="body">
      <div className="help_heading">How to Invite Borrower </div>
      <div className="displayFlex Alignitem_center justifyContent_center flexDirection pb30">
        <div className="row">
          <div className="col-md-12">
            <p className="mb_24" style={{fontSize: '14px'}}>
            The service provider or dealer will be entertained to invite the borrower by sharing the URL dedicated to the dealer.  
            </p>
            <div><img width={900} height={320} src={InviteBorrower} alt="how-to-invite-borrower" /></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToInviteBorrower;
