import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import profileiconIcon from "../../assets/slices/profile.svg";
import { io } from "socket.io-client";

const UserAvatar = ({business, profile, changePassword, quickGuide, logout}) => {
  let tenant = sessionStorage.getItem("tenantId");
  let subs_token = sessionStorage.getItem("tenantToken");
  const queryParams = useLocation().pathname.split("/")[1];
  const navigate = useNavigate();

  const handlelogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const gotoSubscription = () => {
    navigate(`/tenant-dashboard/${tenant}`);
  };

  const gotoBusiness = () => {
    navigate(`/businessinfo/${tenant}`);
  };

  const gotoProfile = () => {
    navigate(`/profile/${tenant}`);
  };

  const gotoChangePassword = () => {
    navigate("/change-password");
  };

  const gotodocs = () => {
    navigate("/docs/home");
  };

  const gotoConfig = () => {
    navigate(
      `/control-panel/dashboard/${sessionStorage.getItem(
        "subscriptionId"
      )}/${subs_token}`
    );
  };

  return (
    <ul className="user_setting">
      <li>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <img src={profileiconIcon} alt="avatar" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* <Dropdown.Item onClick={gotoSubscription}>
                    My Subscription
                  </Dropdown.Item> */}
            {business === true && (
              <Dropdown.Item
                className={
                  queryParams === "businessinfo" ? "menuActiveDropdown" : ""
                }
                onClick={gotoBusiness}
              >
                My Business
              </Dropdown.Item>
            )}

            {profile === true && (
              <Dropdown.Item
                className={
                  queryParams === "profile" ? "menuActiveDropdown" : ""
                }
                onClick={gotoProfile}
              >
                My Profile
              </Dropdown.Item>
            )}

            {changePassword === true && (
              <Dropdown.Item
                className={
                  queryParams === "change-password" ? "menuActiveDropdown" : ""
                }
                onClick={gotoChangePassword}
              >
                Change Password
              </Dropdown.Item>
            )}

            {quickGuide === true && (
              <Dropdown.Item
                className={queryParams === "docs" ? "menuActiveDropdown" : ""}
                onClick={gotodocs}
              >
                Quick guide
              </Dropdown.Item>
            )}

            {/* <Dropdown.Item onClick={gotoConfig}>
                  Configuration
                </Dropdown.Item> */}

            {logout === true && (
              <Dropdown.Item onClick={handlelogout}>Log out</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </li>
    </ul>
  );
};

export default UserAvatar;
