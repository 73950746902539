export const json1hr = [
  {
    id: 1,
    time: "12.00AM",
    value: "12am ",
  },
  {
    id: 2,
    time: "01.00AM",
    value: "1am ",
  },
  {
    id: 3,
    time: "02.00AM",
    value: "2am ",
  },
  {
    id: 4,
    time: "03.00AM",
    value: "3am ",
  },
  {
    id: 5,
    time: "04.00AM",
    value: "4am ",
  },
  {
    id: 6,
    time: "05.00AM",
    value: "5am ",
  },
  {
    id: 7,
    time: "06.00AM",
    value: "6am ",
  },
  {
    id: 8,
    time: "07.00AM",
    value: "7am ",
  },
  {
    id: 9,
    time: "08.00AM",
    value: "8am ",
  },
  {
    id: 10,
    time: "09.00AM",
    value: "9am ",
  },
  {
    id: 11,
    time: "10.00AM",
    value: "10am ",
  },
  {
    id: 12,
    time: "11.00AM",
    value: "11:00am ",
  },
  {
    id: 13,
    time: "12.00PM",
    value: "12:00pm ",
  },
  {
    id: 14,
    time: "01.00PM",
    value: "01:00pm ",
  },
  {
    id: 15,
    time: "02.00PM",
    value: "02:00pm ",
  },
  {
    id: 16,
    time: "03.00PM",
    value: "03:00pm ",
  },
  {
    id: 17,
    time: "04.00PM",
    value: "04:00pm ",
  },
  {
    id: 18,
    time: "05.00PM",
    value: "05:00pm ",
  },
  {
    id: 19,
    time: "06.00PM",
    value: "06:00pm ",
  },
  {
    id: 20,
    time: "07.00PM",
    value: "07:00pm ",
  },
  {
    id: 21,
    time: "08.00PM",
    value: "08:00pm ",
  },
  {
    id: 22,
    time: "09.00PM",
    value: "09:00pm ",
  },
  {
    id: 23,
    time: "10.00PM",
    value: "10:00pm ",
  },
  {
    id: 24,
    time: "11.00PM",
    value: "11:00pm ",
  },
];
