import * as Yup from "yup";
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { EmailRegex } from "./regex";

export const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i.test(
      values.password
    )
  ) {
    errors.password = "Invalid password";
  }

  if (!values.businessName) {
    errors.businessName = "Business name is required";
  }

  return errors;
};

const validateVertical = (values) => {
  const errors = {};

  if (!values.businessName) {
    errors.businessName = "Businessname is required";
  }

  if (!values.verticalId) {
    errors.verticalId = "Select a vertical";
  }

  return errors;
};
export default validateVertical;

export const RegisterValidate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Password is required";
  } 
  // else if (
  //   !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i.test(
  //     values.password
  //   )
  // )
  //  {
  //   errors.password = "Invalid password";
  // }

  return errors;
};

export const validateRegister = ( values ) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors
}

export const forgetpwValidate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

export const validationResetPassword = (values) => {
  const errors = {};

  if (!values.newPassword) {
            errors.newPassword = "New password is required";
        }
  else if(!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,16}$/.test(values.newPassword)) {
    errors.newPassword ="Must Contain 8 to 16 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = "Confirm Password is required";
  } else if(values.confirmPassword !== values.newPassword) {
    errors.confirmPassword = "Must Match With Password";
  }

  return errors
}

export const validationSignUp = (values) => {
  const errors = {};

  if (!values.contactName) {
    errors.contactName = "Contact Name is required";
  }

  if (!values.businessName) {
    errors.businessName = "Business Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Password is required";
  // } else if(values.password.length < 8) {
  //   errors.password = "Password length must be 8 characters minimum";
  } else if(!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,16}$/.test(values.password)) {
    errors.password ="Must Contain 8 to 16 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = "Confirm Password is required";
  } else if(values.confirmPassword !== values.password) {
    errors.confirmPassword = "Must Match With Password";
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = "Phone Number is required";
  } else if(!isValidPhoneNumber(values.phoneNumber === undefined ? "" : values.phoneNumber)) {
    errors.phoneNumber = "Invalid Phone Number";
  }

  if (!values.country) {
    errors.country = "Country is required";
  } 
  
  return errors;

}


export const validationContractfrom = (values) => {
  console.log('values::: ', values);
  const errors = {};

  if (!values.companyName) {
    errors.companyName = "Organization is required";
  }

  if (!values.name) {
    errors.name = "Name is required";
  }

  if (!values.comments) {
    errors.comments = "Comments is required";
  }
 
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.phone) {
    errors.phone = "Phone Number is required";
  } else if(!isValidPhoneNumber(values.phone === undefined ? "" : values.phone)) {
    errors.phone = "Invalid Phone Number";
  }

  if (!values.country) {
    errors.country = "Country is required";
  } 
  
  if (!values.timezone) {
    errors.timezone = "Timezone is required";
  } 
  if (!values.hour) {
    errors.hour = "Hour slots is required";
  }

  return errors;

}
//   contactName: Yup.string()
//     .required("*Contact Name Is Required")
//     .min(1, "*Contact Name Must Be Between 1 and 50 Characters")
//     .max(50, "*Contact Name Must Be Between 1 and 50 Characters"),

//   email: Yup.string()
//     .required("*Email Address Is Required")
//     .matches(EmailRegex, "*Enter A Valid Email Address"),

//   password: Yup.string().required("Password Is Required"),
  
//   confirmPassword: Yup.string()
//     .required("Password Is Required")
//     .test('passwords-match', 'Incorrect Password', function(value){
//       return this.parent.password === value
//     }),

//   businessName: Yup.string()
//     .required("*Business Name Is Required")
//     .min(1, "*Business Name Must Be Between 1 and 50 Characters")
//     .max(50, "*Business Name Must Be Between 1 and 50 Characters"),

//   phoneNumber: Yup.string()
//     .required("*Phone Number Is Required")
//     .test("length", "Invalid Phone Number", (val) =>
//       isValidPhoneNumber(val === undefined ? "" : val)
//     ),
// });

export const validateOtp = (values) => {

  const errors = {};

  if (!values.phoneotp) {
    errors.phoneotp = "Phone OTP is required";
  } else if (values.phoneotp.length > 6) {
    errors.phoneotp = "Invalid OTP Length";
  }

  if (!values.emailotp) {
    errors.emailotp = "Email OTP is required";
  } else if (values.emailotp.length > 6) {
    errors.emailotp = "Invalid OTP Length";
  }
  return errors;
}