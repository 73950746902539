import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../../../assets/slices/edit_icon.svg";

const Docs = ({htmlContent,documentList}) => {
  console.log('documentList::: ', documentList);
  console.log('htmlContent::: ', htmlContent);
  const navigate = useNavigate()
  const [documents, setDocuments] = useState(documentList);
  const [docContent, setDocContent] = useState(htmlContent);


  const gotoEditor = (e, fileName) => {
    navigate(`/control-panel/editdocument/${fileName}`)
  }
  return (
    <>

      <div className="container-fluid">
        <div className="row p0">
          <div className="col-md-12">
            <div className="whiteboxblock100per">

              <h3 className="  mb_40  sectiontitle ">
                Finance Documents
              </h3>


              <div className="Table_Wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Document</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documentList?.map((document, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{document?.documentName}</td>
                        <td>
                          {/* <i
                    title="View"
                    style={{ cursor: "Pointer" }}
                    className="fa fa-eye"
                  ></i>
                  &nbsp;&nbsp; &nbsp; */}
                          {/* <i style={{ cursor: "Pointer" }} className="fa fa-edit"></i> */}
                          <button
                            className="emptyBtn"
                            onClick={(e) => gotoEditor(e, document.fileName)}
                            title="Edit"
                          >
                            <img src={EditIcon} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>


            </div>



          </div>

        </div>



      </div>









    </>
  );
};

export default Docs;
