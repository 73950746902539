import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Pie } from "react-chartjs-2";

Chart.register(CategoryScale);

// data props array example model: [{name: 'string', count: 0}]
const PieChart = ({
  label,
  data,
  radius,
  hoverOffset,
  borderColor,
  borderWidth,
}) => {
  let pieChartData = {
    labels: [],
    datasets: [
      {
        label,
        data: [],
        backgroundColor: [
          "#672c2c",
          "#eedcdc",
          "#e4d7d7",
          "#ff575a",
          "#ad57a8",
          "#76c46c",
        ],
        radius,
        hoverOffset,
        borderColor,
        borderWidth,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'center',
        labels: {
          font: {
            size: 10
          },
          boxWidth: 12,
          boxHeight: 12
        },
      }
    },
    maintainAspectRatio: false,
  }

  data?.map((ele) => {
    pieChartData.labels.push(ele.name);
    pieChartData.datasets[0].data.push(ele.count);
  });

  return <Pie data={pieChartData} options={options} />;
};

export default PieChart;
