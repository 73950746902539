import React, { useState } from "react";
import PlusWhite from "../assets/images/plus_white.svg";
import brandlogo from "../assets/images/brand_logo.svg";
import { useNavigate } from "react-router-dom";
import UserAvatar from "./UserAvatar/UserAvatar";

const Header = () => {
  const navigate = useNavigate();

  const handleNewSubscription = () => {
    sessionStorage.removeItem("ProductId");
    sessionStorage.removeItem("selectedthemeid");
    sessionStorage.removeItem("VerticalId");
    sessionStorage.removeItem("tenantID");
    sessionStorage.removeItem("themeId");
    sessionStorage.removeItem("subscriptionId");
    sessionStorage.removeItem("selectedVertical");
    sessionStorage.removeItem("businessName");
    sessionStorage.setItem("newSubscription", true);
    navigate("/vertical");
  };

  return (
    <>
      <div className="topHeader">
        <div className="logosection headerinherit">
          <div className="displayFlex">
            {/* <img src={menuicon} className="mobileMenuicon" /> */}
            <div className="brand_logo">
              <img src={brandlogo} className="whiteFilter" alt="brandlogo" />
            </div>
          </div>
        </div>
        <div className="displayFlex userProfileicon">
          <div className="mr_20" onClick={handleNewSubscription}>
            <button className="devilLip-btn devilLip-primaryClr">
              <img width={20} height={20} src={PlusWhite} alt="add" />
              Add Subscription
            </button>
          </div>
          {/* user avatar */}
          <UserAvatar
            business={true}
            profile={true}
            changePassword={true}
            quickGuide={true}
            logout={true}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
