import React, { useState, useEffect } from "react";
import { setNestedObjectValues, useFormik } from "formik";
import { validate } from "../../_common/Validation";
import { Post, AuthGet } from "../../_common/httpService";
import { Get } from "../../_common/httpService";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import mainLogo from "../../assets/slices/main_logo.svg";
import welcome from "../../assets/slices/welcome_img.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import usericon from "../../assets/slices/userIcon.svg";
import passwordicon from "../../assets/slices/password_icon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { useStateValue } from "../../Context/StateProvider";
import Toast from "../../Components/Toast";

const Login = () => {
  const navigate = useNavigate();
  const [verticals, setVerticals] = useState([
    { id: 1, name: "a" },
    { id: 2, name: "b" },
    { id: 3, name: "c" },
  ]);
  const [{}, dispatch] = useStateValue();

  useEffect(() => {
    AuthGet("vertical/list/all").then((res) => {
      return setVerticals(res.data.tenants);
    });
  }, []);

  const { touched, errors, handleSubmit, values, handleChange, setValues } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
        businessName: "",
        verticalId: "",
      },

      onSubmit: (values) => {
        Post("tenant/getstarted", values)
          .then((res) => {
            if (res.statusCode === 200) {
              toast.success(res.message[0], {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              toast.error(res.message[0], {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((err) => {
            toast.error(err, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      },
    });

  return (
    <>
      <Toast />
      <div className="fullScreen_bg">
        <div className="container">
          <div className="parentGrid Customerpanel_rectangle height_600">
            <div className="ColumnLeft">
              <Carousel
                infiniteLoop
                useKeyboardArrows
                autoPlay
                showThumbs={false}
                showStatus={false}
                showArrows={false}
              >
                <div className="welcome_content">
                  <img src={welcome} />
                  <h3>Welcome to One BaaS</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing Lorem Ipsum
                    has been the industry.
                  </p>
                </div>

                <div className="welcome_content">
                  <img src={welcome} />
                  <h3>Welcome to One BaaS</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing Lorem Ipsum
                    has been the industry.
                  </p>
                </div>

                <div className="welcome_content">
                  <img src={welcome} />
                  <h3>Welcome to One BaaS</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing Lorem Ipsum
                    has been the industry.
                  </p>
                </div>
              </Carousel>
            </div>
            <div className="ColumnRight">
              <div className="main_logo">
                <img src={mainLogo} />
              </div>
              <form onSubmit={handleSubmit} className="account_frame">
                <div className="formGroup mb_24">
                  <div className="input-group-prepend">
                    <span className="inputGroup_text">
                      <img src={usericon} />
                    </span>
                  </div>
                  <input
                    type="text"
                    id="email"
                    placeholder="Email Address"
                    value={values.email}
                    className="Main_input"
                    onChange={handleChange}
                  />
                  {errors.email && touched.email ? (
                    <div className="error_text">{errors.email}</div>
                  ) : null}
                </div>

                <div className="formGroup mb_16">
                  <div className="input-group-prepend">
                    <span className="inputGroup_text">
                      <img src={passwordicon} />
                    </span>
                  </div>

                  <input
                    type="password"
                    id="pass"
                    name="password"
                    placeholder="Your Password"
                    value={values.password}
                    className="Main_input"
                    onChange={handleChange}
                  />
                  {/* <small>
                  Password should contain atleast one uppercase letter,
                  lowercase letter, special character and number.
                </small> */}
                  {errors.password && touched.password ? (
                    <div className="error_text">{errors.password}</div>
                  ) : null}
                </div>

                <div className="formGroup mb_16">
                  <div className="input-group-prepend">
                    <span className="inputGroup_text"></span>
                  </div>

                  <input
                    type="text"
                    id="business_name"
                    name="businessName"
                    placeholder="Business Name"
                    value={values.businessName}
                    className="Main_input"
                    onChange={handleChange}
                  />

                  {errors.businessName && touched.businessName ? (
                    <div className="error_text">{errors.businessName}</div>
                  ) : null}
                </div>

                <div className="formGroup mb_16">
                  <div className="input-group-prepend">
                    <span className="inputGroup_text"></span>
                  </div>

                  {verticals.map((ele, index) => (
                    <>
                      <label
                        style={{ marginRight: "20px" }}
                        onClick={() =>
                          setValues({ ...values, verticalId: ele.id })
                        }
                      >
                        <input
                          type="radio"
                          id="vertialId"
                          name="verticalId"
                          value={ele.id}
                          onChange={handleChange}
                          key={index}
                        />
                        <span style={{ color: "gray" }}>{ele.name}</span>
                      </label>
                    </>
                  ))}

                  {errors.businessName && touched.businessName ? (
                    <div className="error_text">{errors.businessName}</div>
                  ) : null}
                </div>

                <button type="submit" className="Mainbutton">
                  Submit
                </button>
                <ToastContainer />
                {/* <span>
                Create a new account? <a href="/register">Register</a>
              </span> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
