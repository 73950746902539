import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const TenantGuard = () => {
  const token = sessionStorage.getItem("tenantToken");
  const role = token ? JSON.parse(atob(token.split(".")[1])).role : null;
  const expiry = token ? JSON.parse(atob(token.split(".")[1])).exp : null;

  return (
    <>
      <div>
        {role === "tenant" &&
        expiry > Math.floor(new Date().getTime() / 1000) ? (
          <Outlet />
        ) : (
          <Navigate to="/login" />
        )}
      </div>
    </>
  );
};

export default TenantGuard;
