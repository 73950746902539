import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { RegisterValidate } from "../../_common/Validation";
import { Post } from "../../_common/httpService";
import { Get } from "../../_common/httpService";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import mainLogo from "../../assets/slices/main_logo.svg";
import welcome from "../../assets/slices/welcome_img.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import usericon from "../../assets/slices/userIcon.svg";
import passwordicon from "../../assets/slices/password_icon.svg";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { useStateValue } from "../../Context/StateProvider";
import Toast from "../../Components/Toast";
import { toast } from "react-hot-toast";
import BannertwoImg from "../../assets/slices/bannertwo.png";
import BannerthreeImg from "../../assets/slices/bannerthree.png";
import EyeHide from "../../assets/slices/eye_hide.svg";
import EyeShow from "../../assets/slices/eye_show.svg";

const Login = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("New");
  const [{ }, dispatch] = useStateValue();
  const [passwordVisibilty, setPasswordVisibilty] = useState("password")

  // useEffect(() => {
  //   let token = sessionStorage.getItem("tenantToken")
  //   if(token){
  //     window.history.forward()
  //   }
  // },[])

  useEffect(() => {
    if (window.history && window.history.pushState) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function (event) {
        window.history.pushState(null, null, window.location.href);
      };
    }
    sessionStorage.clear();
  },[]);

  //login to tenant-dashboard with tenantID
  const getsubscriptionlist = async (tenantId) => {
    await Get(`subscription/getsubscriptionList/${tenantId}`)
      .then((res) => {
        res.data?.subscriptions.length > 0
          ? navigate(`/docs/home`)
          : navigate("/vertical");
      })
      .catch((err) => {
      });
  };

  const { touched, errors, handleSubmit, values, handleChange, handleBlur } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: RegisterValidate,
    onSubmit: (values) => {
      let data = values;
      Post("tenant/login", data)
        .then((res) => {
          if (res.statusCode === 400) {
            if(res.statusCode === 400 && res.message?.length > 0) {
              toast.error(res.message);
            }
            sessionStorage.setItem("tenantId", res?.subscription_details?.tenant_id);
            sessionStorage.setItem("subscriptionId", res?.subscription_details?.subscription_id);
            sessionStorage.setItem("ProductId", res?.subscription_details?.productId);
            sessionStorage.setItem("VerticalId", res?.subscription_details?.verticalId);
            sessionStorage.setItem("tenantToken", res?.subscription_details?.tenantToken);
            sessionStorage.setItem("businessName", res?.subscription_details?.businessName);
            if (res?.subscription_details?.step == 2) {
              navigate(`/vertical`);
            } else if (res?.subscription_details?.step == 3) {
              navigate("/upload-logo", { replace: true });
            } else if (res?.subscription_details?.step == 4) {
              navigate("/theme");
            } else if (res?.subscription_details?.step == 5) {
              navigate('/verify-otp')
            }
          }
          else if (res.statusCode === 200) {
            // toast.success("success")
            let tenantID = res.data.tenantId;
            sessionStorage.setItem("tenantId", tenantID);
            sessionStorage.setItem("tenantToken", res.data.data);
            getsubscriptionlist(tenantID);
          }
        })
        .catch((err) => {
          const errObj = JSON.parse(err.message);
          toast.error(errObj.data.message);
        });
    },
  });

  return (
    <>
      <Toast />
      <div className="fullScreen_bg displayFlex Alignitem_center">
        <div className="container_wrapper">
          <div className="row Customerpanel_rectangle height_600">
            <div className="ColumnLeft col-md-5 pzero">
              <div className="deznav">
                <ul>
                  <li>
                    <NavLink to="/" exact activeClassName="active">
                      LOGIN
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/register" activeClassName="active">
                      SIGN UP
                    </NavLink>
                  </li>
                </ul>
              </div>
              <Carousel
                infiniteLoop
                useKeyboardArrows
                autoPlay
                showThumbs={false}
                showStatus={false}
                showArrows={false}
              >
                <div className="welcome_content">
                  <img src={welcome} />
                  <h3>Welcome to Theecode {message}</h3>
                  <p>
                    Transforming your fintech dream into reality with our
                    seamless and innovative Tech solutions
                  </p>
                </div>

                <div className="welcome_content">
                  <img src={BannertwoImg} />
                  <h3>Welcome to Theecode</h3>
                  <p>
                    Transforming your fintech dream into reality with our
                    seamless and innovative Tech solutions
                  </p>
                </div>

                <div className="welcome_content">
                  <img src={BannerthreeImg} />
                  <h3>Welcome to Theecode</h3>
                  <p>
                    Transforming your fintech dream into reality with our
                    seamless and innovative Tech solutions
                  </p>
                </div>
              </Carousel>
            </div>
            <div className="col-md-7 pzero displayFlex Alignitem_center justifyContent_center whiteBg">
              <div>
                <div className="main_logo">
                  <img src={mainLogo} />
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="account_frame width_400"
                >
                  <div><div className="formGroup mb_24">
                    <div className="input-group-prepend">
                      <span className="inputGroup_text">
                        <img src={usericon} className="width30" />
                      </span>
                    </div>
                    <input
                      type="text"
                      id="email"
                      placeholder="Email Address"
                      value={values.email?.replace(/[ ]/g, "")}
                      className="Main_input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <div className="error_text errormargin">{errors.email}</div>
                    ) : null}

                  </div>
                    </div>

                  <div className="formGroup mb_24">
                    <div className="input-group-prepend">
                      <span className="inputGroup_text">
                        <img src={passwordicon} className="width30 height30" />
                      </span>
                    </div>

                    <input
                      type={passwordVisibilty}
                      id="pass"
                      name="password"
                      placeholder="Your Password"
                      value={values.password?.replace(/[ ]/g, "")}
                      className="Main_input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <div className="input-group-prepend">
                      <span className="inputGroup_text">
                        <img
                          className="width20"
                          src={
                            passwordVisibilty == "password" ? EyeHide : EyeShow
                          }
                          onClick={() => {
                            passwordVisibilty == "password"
                              ? setPasswordVisibilty("text")
                              : setPasswordVisibilty("password");
                          }}
                        />
                      </span>
                    </div>
                    {errors.password && touched.password ? (
                    <div className="error_text errormargin">{errors.password}</div>
                  ) : null}
                    {/* <small>
                  Password should contain atleast one uppercase letter,
                  lowercase letter, special character and number.
                </small> */}
                  </div>
                  {/* {errors.password && touched.password ? (
                    <div className="error_text">{errors.password}</div>
                  ) : null} */}

                  <div className="displayFlex justifyContent_End">

                  <Link
                    to="/forgot-password"
                    className="forgotPassword_link mb_30"
                  >
                    Forgot Password?
                  </Link>
                  </div>
                  <button type="submit" className="Mainbutton  ">
                    Login
                  </button>
                  {/* <span>
                Create a new account? <a href="/register">Register</a>
              </span> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
