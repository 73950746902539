import { useNavigate } from "react-router-dom";
import brandlogo from "../../assets/images/brand_logo.svg";
import emailBanner from "../../assets/images/contact_form.svg";
import { useEffect } from "react";
import UserAvatar from "../../Components/UserAvatar/UserAvatar";

export const ThanksPage = () => {
  const navigate = useNavigate();
  const addNewSubscription = JSON.parse(sessionStorage.getItem("newSubscription"));

  useEffect(() => {
    setTimeout(() => {
      if (addNewSubscription === true) {
        navigate(`/docs/home`);
      } else {
        navigate("/login")
      }
    }, [5000])
  }, [])

  return (
    <>
      <div className="fullScreen_bg p0">
      <div className="topHeader">
        <div className="displayFlex justifyContent_spaceBetween Alignitem_center width_100per">
          <div>
            {" "}
            <div className="logosection headerinherit">
              <div className="displayFlex">
                {/* <img src={menuicon} className="mobileMenuicon" /> */}
                <div className="brand_logo">
                  <img src={brandlogo} className="whiteFilter" />
                </div>
              </div>
            </div>
          </div>

          <div className="mr_20">
          {
            addNewSubscription === true ? (
              <UserAvatar logout={true} quickGuide={true} business={true} changePassword={true} profile={true} />
            ) : (
              <UserAvatar logout={true} />
            ) 
          }
          </div>
        </div>
      </div>

        <div className="whiteboxblock2 displayFlex justifycontencenter flexDirection AlignItemCenter">
          <img src={emailBanner} className="" />

          <div>
            {" "}
            <div className="congrattext textAlignCenter">Thank you</div>
            <div>
              {/* Set Password link is sent to your email address. Please verify. */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
