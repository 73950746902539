import React from "react";
import { useFormik } from "formik";
import { validate } from "../../_common/Validation";
import { AuthPost } from "../../_common/httpService";
import { forgetpwValidate } from "../../_common/Validation";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Toast from "../../Components/Toast";
import { toast } from "react-hot-toast";
const ForgotPassword = () => {
  const navigate = useNavigate()
  const { values, handleChange, handleSubmit, handleBlur,errors, touched } = useFormik({
    initialValues: {
      email: "",
    },
    validate: forgetpwValidate,
    onSubmit: (values) => {
      let data = values;
      AuthPost("tenant/forgot-password", data)
        .then((res) => {
          if (res.statusCode === 200) {
            toast.success(res.message[0])
            navigate("/login")
          }
          else {
            toast.error(res.message)
          }
        })
        .catch((err) => {
        });
    },
  });
  return (
    <div className="fullScreen_bg">
      <Toast />
      <div className="container">
        <div className="otpblock">
          <div className="textAlign_center">
            {/* <div className="main_logo">
              <img src={atg} />
            </div> */}
            <h3 className="     sectiontitle  mb_30">
              Forgot Your Password?
            </h3>



            <p className="subparagraph_text mb_24">
              Check your email for your password reset.
            </p>
            <div className="title_border mb_30"></div>
            <form onSubmit={handleSubmit} className="account_frame">
              <div className="  mb_24">
                <input
                  type="text"
                  className="Main_input"
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  autoComplete="off"
                  value={values.email?.replace(/[ ]/g, "")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <div className="error_text">{errors.email}</div>
                ) : null}
              </div>

              <button type="submit" className="Mainbutton">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
