import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import theecodelogo from "../../assets/images/logo_theecode.svg";
import dropdown from "../../assets/images/dropdown_icon.svg";
import GetStartedIcon from "../../assets/images/getStarted_icon.svg";
import ServiceproviderIcon from "../../assets/images/service_provider.svg";
import InviteborrowerIcon from "../../assets/images/invite_borrower.svg";
import BrandingpageIcon from "../../assets/images/branding_page.svg";
import HomeIcon from "../../assets/images/home_icon.svg";
import LendingappIcon from "../../assets/images/lending_app.svg";

function Landingsidebar() {
  const navigate = useNavigate();
  const queryParams = useLocation().pathname.split("/")[2];
  return (
    <>
      <div className="AdminSidemenu">
        <div className="menuBlock mt_20 mb_24 ">
          <ul>
            <li className={queryParams === "home" ? "menuActive" : ""}>
              <button onClick={() => navigate("/docs/home")}>
                {/* <div className='menuIcon'>   s</div> */}
                <img src={HomeIcon} alt="HomeIcon" />
                <span> Home</span>
              </button>
            </li>
          </ul>
        </div>
        <div className=" sidebarmenuTitle">Quick Guide </div>

        <div className="menuBlock ">
          <ul>
            {/* <li
              className={queryParams === "getting-started" ? "menuActive" : ""}
              onClick={() => navigate("/docs/getting-started")}
            >
              <button>
                <img src={GetStartedIcon} alt="GetStartedIcon" />
                <span> Getting started</span>
              </button>
            </li> */}
            <li
              className={queryParams === "lending-app" ? "menuActive" : ""}
              onClick={() => navigate("/docs/lending-app")}
            >
              <button>
                <img src={LendingappIcon} alt="LendingappIcon" />
                <span> How your lending app works</span>
              </button>
            </li>
            <li
              className={
                queryParams === "how-to-add-service-providers"
                  ? "menuActive"
                  : ""
              }
              onClick={() => navigate("/docs/how-to-add-service-providers")}
            >
              <button>
                <img src={ServiceproviderIcon} alt="ServiceproviderIcon" />
                <span> How to add service provider</span>
              </button>
            </li>
            <li
              className={
                queryParams === "how-to-invite-borrower" ? "menuActive" : ""
              }
              onClick={() => navigate("/docs/how-to-invite-borrower")}
            >
              <button>
                <img src={InviteborrowerIcon} alt="InviteborrowerIcon" />
                <span> How to invite borrower </span>
              </button>
            </li>
            <li
              className={queryParams === "brand-page" ? "menuActive" : ""}
              onClick={() => navigate("/docs/brand-page")}
            >
              <button>
                <img src={BrandingpageIcon} alt="BrandingpageIcon" />
                <span> Your branding page </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Landingsidebar;
