import React from "react";
import ConfigImg from './assets/config_image.png'
import UploadAddImg from './assets/upload_image.png'
import UploadLogoImg from './assets/upload_logo.png'
import ThemeImg from './assets/theme.png'

const BrandPage = () => {
  return (
    <div style={{backgroundColor: '#fff'}} className="body">
      <div className="help_heading">Your branding page </div>
      <div
        style={{ paddingLeft: "30px" }}
        className="container pb30"
      >
        <div className="row">
          <div className="col-md-12">
            <p className="platinumGraniteText mb24 fs14">
              While onboarding a Lender, GLENZY demands their logo and the theme
              to be used for the application. On successful subscription and
              publishing of application, the Lender admin is empowered to modify
              both the logo and theme.<br/><br/>

              GLENZY enables the Lenders to perform basic branding of the lending application with their logo and theme.  It can be achieved by utilizing the “<span className="primary_color">Configure</span>” menu.  
            </p>

            <div><img src={ConfigImg} alt="config_image" /></div>

            <p style={{paddingTop: "30px"}} className="platinumGraniteText mb24 fs14">
              On click of “<span className="primary_color">Configure</span>” menu, it will lead to a page with multiple tabs which will help in customization of UI and contents.
            </p>
          </div>

          <div className="col-md-12">
            <div className="">
              <h5 className="mb_12">Logo</h5>
              <p className="platinumGraniteText mb24 fs14">
                The Lender admin is allowed to modify the logo by adding it using <img width={25} height={25} src={UploadAddImg} /> On adding the logo, it enables them to have a preview of it.<br/><br/>
                On click of “<span className="primary_color">Upload</span>” button, the logo will be saved and get reflected in the published application.
              </p>
            </div>
            <div className="">
              <img className="stepImg" src={UploadLogoImg} alt="" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h5 className="mb_12">Theme</h5>
            <p className="platinumGraniteText fs14">
              The Lender admin is enabled to modify the theme of the published application. The user needs to select a theme and click the “<span className="primary_color">Update</span>” button.
            </p>

            <div>
              <img className="stepImg" src={ThemeImg} alt="" />
            </div>

            <p style={{paddingTop: "30px"}} className="platinumGraniteText fs14">
              The updated logo or theme, upon refreshing, will immediately reflect in the application. 
            </p> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandPage;
