import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  AuthGet,
  geturl,
  AuthPost,
  AuthPut,
} from "../../../../_common/httpService";
import downArrow from "../../../../assets/slices/down-arrow.svg";
import upArrow from "../../../../assets/slices/up-arrow.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Theme from "../../Tabs/Theme/Theme";
import Logo from "../../Tabs/Logo/Logo";
import Apps from "../../Tabs/Apps/Apps";
import Api from "../../Tabs/Api/Api";
import EmailTemplate from "../../Tabs/EmailTemplate/EmailTemplate";
import Docs from "../../Tabs/Docs/Docs";
import TextEditor from "../../Components/Editor";
import Formbuilder from "../../Tabs/Formbuilder/Formbuilder";
import DecisionEngine from "../../Tabs/DecisionEngine/DecisionEngine";

import ThemeIcon from "../../../../assets/slices/themeIcon.svg";
import LogoIcon from "../../../../assets/slices/logoicon.svg";
import AppsIcon from "../../../../assets/slices/apps_icon.svg";
import ApiIcon from "../../../../assets/slices/apiIcon.svg";
import DocumentIcon from "../../../../assets/slices/document_icon.svg";
import EmailtemplateIcon from "../../../../assets/slices/emailtemplate_icon.svg";
import FormBuilderIcon from "../../../../assets/slices/formBuilderIcon.svg";
import DecisionengineIcon from "../../../../assets/slices/decisionengine_icon.svg";
import brandlogo from "../../../../assets/images/brand_logo.svg";

import menuicon from "../../../../assets/images/menu-icon.svg";
import Header from "../../../../Components/Header";
import UserAvatar from "../../../../Components/UserAvatar/UserAvatar";


const Dashboard = () => {
  const [subscription, setSubscription] = useState([
    {
      createdAt: "",
      updatedAt: "",
      verticalname: "",
      productname: "",
      publish: "",
      status: "",
      verticalId: "",
      productId: "",
    },
  ]);
  const [logo, setLogo] = useState("");
  const [theme, setTheme] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState({});
  const [apiUrl, setApiUrl] = useState("");
  const [docs, setDocs] = useState([]);
  const [htmlContent, setHtmlContent] = useState([]);
  const [etemplate, setEtemplate] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [isopen, setIsopen] = useState(true);
  let searchParams = useParams();
  const { id } = useParams();
  useEffect(() => {
    // console.log('id------------------>', id)
    sessionStorage.setItem("subscriptionId", id)
    let subscriptionId = sessionStorage.getItem("subscriptionId");
    console.log("subscriptionId::: ", subscriptionId);
    sessionStorage.setItem("tenantToken", searchParams.token);
    getSubscription(subscriptionId);
    getTenantTheme(subscriptionId);
    let url = geturl(`tenant-theme/logo/${subscriptionId}`);
    setLogo(url);
    getSwaggerUrl(subscriptionId);
    setIsopen(false);
  }, [searchParams]);

  const getSubscription = (id) => {
    AuthGet(`subscription/list1/${id}`).then((res) => {
      if (res.statusCode !== undefined && res.statusCode === 200) {
        let data = res.data.tenants;
        setSubscription(data);
        sessionStorage.setItem("ProductId", data[0].productId);
        sessionStorage.setItem("VerticalId", data[0].verticalId);
        getThemes(data[0].verticalId, data[0].productId);
        getDocuments(data[0].productId);
      }
    });
  };

  const getThemes = (id1, id2) => {
    AuthGet(`tenant-theme/themelist/${id1}/${id2}`).then((res) => {
      if (res.statusCode === 200) {
        let response = res.data;
        setTheme(response);
      }
    });
  };

  const getTenantTheme = (id) => {
    AuthGet(`tenant-theme/list-theme/${id}`).then((res) => {
      if (res.statusCode === 200) {
        let data = res.data.tenants[0];
        setSelectedTheme(data);
      }
    });
  };

  const getSwaggerUrl = (id) => {
    console.log("ID", id);
    AuthGet(`control-panel/apilist/${id}`).then((res) => {
      if (res.statusCode === 200) {
        setApiUrl(res.data[0].url);
      }
    });
  };

  const getDocuments = (id) => {
    AuthGet(`documentmaster/document/${id}`).then((res) => {
      if (res["statusCode"] == 200) {
        let data = res["data"];
        setDocs(data);
        let content = [];
        data.map((doc) => {
          let data = {
            documentName: doc.documentName,
            htmldata: JSON.parse(doc.content),
          };
          content.push(data);
        });
        setHtmlContent(content);
      }
    });
  };

  const handleArrowClick = (e, isopen) => {
    e.preventDefault();
    console.log("isopen::", isopen);
    // setIsopen(isopen);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="fullScreen_bg p0">
      <div className="  ">
        <div className="  pb_24">

          {/* <Header/> */}
          {/* <div className="displayFlex Alignitem_center justifyContent_spaceBetween p24">
            <h2 className="headingTitle24 HorrorSnob_Color">Product Details</h2>
            <div className="userDetail">
              <h1>Hi, Welcome</h1>
              <p>All your products in one place!</p>
            </div>
          </div> */}

          <div className="logosection">
            <div className="displayFlex">
              {/* <img src={menuicon} className="mobileMenuicon" /> */}
              <div className="brand_logo">
                <img src={brandlogo} className="whiteFilter" alt="brandlogo" />
              </div>
            </div>
          </div>




          {/* <div className=" "> */}
          <div className="dashboard-table ">

            <div className="profileButton">
              <div className="positionDropdown">
                <UserAvatar business={true} changePassword={true} logout={true} profile={true} quickGuide={true} />
              </div>
            </div>
            {/* {isopen ? ( */}
            <div className="productDetailTab">

              <Tabs
                transition={true}
                id="noanim-tab-example"
                activeKey={activeTab}
                onSelect={handleTabClick}
              >
                <div className="brand_logo">
                  <img src={logo} className="whiteFilter" alt="brandlogo" />
                </div>
                <Tab
                  title={
                    <>
                      <div>
                        <img src={LogoIcon} alt="logo" />
                      </div>
                      <div>LOGO</div>
                    </>
                  }
                  eventKey={1}
                >
                  <Logo data={logo} setLogo={setLogo} />
                </Tab>

                <Tab
                  title={
                    <>
                      <div>
                        <img src={ThemeIcon} alt="theme" />
                      </div>
                      <div>THEME</div>
                    </>
                  }
                  eventKey={2}
                >
                  <Theme data={theme} tenantTheme={selectedTheme} />
                </Tab>

                <Tab
                  title={
                    <>
                      <div>
                        <img src={AppsIcon} alt="apps" />
                      </div>
                      <div>APPS</div>
                    </>
                  }
                  eventKey={3}
                >
                  <Apps />
                </Tab>

                {
                  apiUrl ?
                    <Tab
                      eventKey={4}
                      title={
                        <>
                          <div>
                            <img src={ApiIcon} alt="api" />
                          </div>
                          <div>API</div>
                        </>
                      }
                    >
                      <Api apiurl={apiUrl} />
                    </Tab>
                    : ""
                }

                <Tab
                  eventKey={5}
                  title={
                    <>
                      <div>
                        <img src={DocumentIcon} alt="docs" />
                      </div>
                      <div>DOCS</div>
                    </>
                  }
                >
                  <Docs documentList={docs} htmlContent={htmlContent} />
                </Tab>

                <Tab
                  eventKey={6}
                  title={
                    <>
                      <div>
                        <img src={EmailtemplateIcon} alt="email-template" />
                      </div>
                      <div>EMAIL-TEMPLATE</div>
                    </>
                  }
                >
                  <EmailTemplate />
                </Tab>

                {/* <Tab
                  // onClick={() => window.open('https://nocode.theecode.co/', '_blank')}
                  eventKey={7}
                  title={
                    <>
                      <div>
                        <img src={FormBuilderIcon} />
                      </div>
                      <div>LOAN-APP BUILDER</div>
                    </>
                  }
                >
                  <Formbuilder />
                </Tab> */}
                {/* 
                <Tab
                  eventKey={8}
                  title={
                    <>
                      <div>
                        <img src={DecisionengineIcon} />
                      </div>
                      <div>DECISIONENGINE</div>
                    </>
                  }
                >
                  <DecisionEngine />
                </Tab> */}





              </Tabs>
            </div>
            {/* <div>
              </div> */}

            {/* )  */}
            {/* : null} */}
          </div>


          {/* <div className="productDetails">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    Vertical
                  </div>
                  <div className="col-md-6">d</div>
                </div>

              </div>


            </div> */}



          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
