import React, { useEffect, useState } from 'react'
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { AuthGet } from '../../../_common/httpService';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Toast from '../../../Components/Toast';
import { toast } from "react-hot-toast";
import { Post } from "../../../_common/httpService";
import UserAvatar from '../../../Components/UserAvatar/UserAvatar';

const TextEditor = (props) => {
  const navigate = useNavigate()
  let SubscriptionId = sessionStorage.getItem("subscriptionId")
  let subs_token = sessionStorage.getItem("tenantToken")
  const { filename } = useParams()
  let editorState = ""
  const [htmlContent, setHtmlContent] = useState("");
  const [body, setBody] = useState(editorState);
  const [content, setContent] = useState("")
  useEffect(() => {
    getContent()
    editorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(htmlContent)
      ));
    setBody(editorState);
  }, [htmlContent]);


  const onEditorStateChange = (editorState) => {
    setBody(editorState);
  }


  const getContent = () => {
    let Subscription = sessionStorage.getItem("subscriptionId")
    AuthGet(`documentmaster/gethtmlcontent/${filename}/${Subscription}`)
      .then((res) => {
        setHtmlContent(JSON.parse(res.data[0].content));
      })
      .catch((err) => {
      })
  }
  const handleChange = () => {
    setContent(draftToHtml(convertToRaw(body.getCurrentContent())))
  }

  const saveDocument = () => {
    let data = {
      subscriptionId: SubscriptionId,
      content: JSON.stringify(content),
      filename: filename,
    };
    Post(`documentmaster/savedocument`, data)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log("r-->", res)
          toast.success('Document Saved')
          navigate(`/control-panel/dashboard/${SubscriptionId}/${subs_token}`);
        }
      })
      .catch((err) => {
        toast.error(err)
      })
  }

  const syncDocument = () => {
    Post(`configuration/synctemplate/${filename}/${SubscriptionId}`)
      .then((res) => {
        if (res.statusCode === 200) {
          console.log("r-->", res)
        }
      })
      .catch((err) => {
        toast.error(err)
      })
  }
  const gotoconfig = () => {
    navigate(`/control-panel/dashboard/${SubscriptionId}/${subs_token}`);
  }
  return (
    <>
      <Toast />
      <div className="fullScreen_bg">
        <div className="container_wrapper">
          <div className="Customerpanel_rectangle pb_24">
            <UserAvatar business={true} changePassword={true} logout={true} profile={true} quickGuide={true} />

            <div className="p24 editor">
              {/* <h2 className="headingTitle24 HorrorSnob_Color mb_24">
                Contact Authorization
              </h2> */}

              <ul className='customBreadcrumbs mb_24'>
                <li onClick={gotoconfig} style={{ cursor: "pointer" }}>Control-Panel</li>
                <li className='active' style={{ cursor: "pointer" }}>Document-Editor</li>
              </ul>
              <Editor
                // editorStyle={{ border: "3px solid" }}
                editorState={body}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                onChange={handleChange}
              />
            </div>

            <div className="displayFlex Alignitem_center justifyContent_center">
              <div className="mr_12">
                <button
                  type="submit"
                  className="Mainbutton"
                  onClick={saveDocument}
                >
                  Save as Draft
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  className="Main_HoverButton"
                  onClick={syncDocument}
                >
                  Sync
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TextEditor