import httpHandler from './httpHandler'

let token = sessionStorage.getItem("tenantToken");
let optionalHeaders = {
  Authorization: token ? `${token}` : "",
};
export const geturl = (url) => {
  return `${process.env.REACT_APP_API_URL}/${url}`;
};

export const AuthPost = async (url, body) => {
  let activeUrl = geturl(url);
  let data = await httpHandler.post(activeUrl, body, {});
  return data;
};

export const AuthGet = async (url) => {
  let activeUrl = geturl(url);
  let data = await httpHandler.get(activeUrl, "", optionalHeaders);
  return data;
};

export const Get = async (url) => {
  let activeUrl = geturl(url);
  let data = await httpHandler.get(activeUrl, "", {});
  return data;
};

export const Post = async (url, body) => {
  let activeUrl = geturl(url);
  let data = await httpHandler.post(activeUrl, body, {});
  return data;
};

export const AuthPut = async (url, body) => {
  let activeUrl = geturl(url);
  let data = await httpHandler.put(activeUrl, body, optionalHeaders);
  return data;
};

export const AuthFile = async (url, body, method = "post") => {
  let activeUrl = geturl(url);
  if (method === "post") {
    let data = await httpHandler.post(activeUrl, body, {
      "content-type": "multipart/form-data",
      Authorization: `${token}`,
    });
    return data;
  } else if (method === "patch") {
    let data = await httpHandler.patch(activeUrl, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    return data;
  }
};
