import React, { useEffect, useState } from "react";
import "../index.css";
import whiteTick from "../assets/slices/whiteTick.svg";
import { Link, useLocation } from "react-router-dom";
import { AuthGet } from "../_common/httpService";
import { useStateValue } from "../Context/StateProvider";

const Stepper = () => {
  const [{ globalStep, currentStep }, dispatch] = useStateValue();
  let [globalurl, setGlobalUrl] = useState("");
  let imageArray = [
    // { id: 1, image: whiteTick, url: "/register" },
    { id: 1, image: whiteTick, url: "/signup" },
    { id: 2, image: whiteTick, url: "/vertical" },
    // { id: 4, image: whiteTick, url: `/product/${id}` },
    { id: 3, image: whiteTick, url: "/upload-logo" },
    { id: 4, image: whiteTick, url: "/theme" },
    { id: 5, image: whiteTick, url: "/verify-otp" },
  ];
  let forNewSubscription = [
    { id: 1, image: whiteTick, url: "/vertical" },
    { id: 2, image: whiteTick, url: "/vertical" },
    { id: 3, image: whiteTick, url: "/upload-logo" },
    { id: 4, image: whiteTick, url: "/theme" },
    { id: 5, image: whiteTick, url: "/verify-otp" },
  ];
  let subId = sessionStorage.getItem("subscriptionId");
  const addNewSubscription = JSON.parse(sessionStorage.getItem("newSubscription"));

  let fetchStep = async () => {
    const res = await AuthGet(`subscription/getstep/${subId}`);
    if (res.statusCode === 200) {
      dispatch({ type: "SET_STEP", payload: res?.data?.step });
    }
    //  else if (res?.data[0]?.step == 2) {
    //   setSteps(3)
    // } else if ((res?.data[0]?.step == 3)) {
    //   setSteps(4)
    //   // setSteps(res?.data[0]?.step ? res?.data[0]?.step + 2 : 2)
    // } else if (res?.data[0]?.step == 4) {
    //   setSteps(5)
    // }
    // return steps
  };

  useEffect(() => {
    imageArray.filter((arr) => {
      // if (href === arr.url.split("/")[1]) {
      //   setCurrentStep(arr.id);
      // }
      if (globalStep === arr.id) {
        setGlobalUrl(arr.url);
      }
    });
    if (subId) {
      fetchStep();
    }
  }, []);
  
  return (
    <div className="Step_progressbar">
      <ul>
        {addNewSubscription ? forNewSubscription?.map((item) => (
          <li key={item.id}>
            {
              <Link onClick={() => dispatch({type: "SET_CURRENT_STEP", payload: item.id})}
                className={item.id == 1 ? 'complete' : item.id < globalStep ? "complete" : ""}
                to={item.id > globalStep ? globalurl : item.url}
              >
                <img src={item.image} alt={`${item}_image`} />
              </Link>
            }
          </li>
        )) : 
        imageArray?.map((item) => (
          <li key={item.id}>
            {
              <Link onClick={() => dispatch({type: "SET_CURRENT_STEP", payload: item.id})}
                className={item.id < globalStep ? "complete" : ""}
                to={item.id > globalStep ? globalurl : item.url}
              >
                <img src={item.image} alt={`${item}_image`} />
              </Link>
            }
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Stepper;
