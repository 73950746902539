import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useParams } from "react-router-dom";
import { AuthFile, geturl, AuthGet } from "../../../../_common/httpService";
// const fileTypes = ["JPG", "PNG", "GIF", "JPEG", "jpg", "png", "jpeg"];
const fileTypes = ["svg"];

const Logo = (props) => {
  let getlogo1 = useParams();
  const [file, setFile] = useState({});
  const [logo, setLogo] = useState([]);
  const searchParams = useParams();
  const [update, setUpdate] = useState(false);
  let subscriptionId = getlogo1.id;

  const handleChange = (file) => {
    setFile(file);
    setLogo(URL.createObjectURL(file));
    setUpdate(true);
  };

  const getlogo = (subscriptionId) => {
    console.log("subscriptionId::: ", subscriptionId);

    AuthGet(`tenant-theme/logo/${subscriptionId}`).then((res) => {
      setLogo(res);
      setUpdate(false);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let id = sessionStorage.getItem("subscriptionId");
    let subscriptionId = searchParams.id;
    let tenantId = sessionStorage.getItem("tenantId");
    let formData = new FormData();
    formData.append("tenantId", tenantId);
    formData.append("subscriptionId", id);
    formData.append("file", file);

    if (file) {
      id
        ? AuthFile(`tenant-theme/${id}`, formData, "patch").then((res) => {
            if (res.statusCode === 200) {
                // setUpdate(false);
                getlogo(subscriptionId); 
            }
          })
        : AuthFile(`tenant-theme/${id}`, formData, "post").then((res) => {
            if (res.statusCode === 200) {
                getlogo(subscriptionId); 

              // const objectUrl = URL.createObjectURL(file);
              // props.setLogo(objectUrl)
            }
          });
    }
  };

  useEffect(() => {
    console.log("subscriptionId::: ", subscriptionId);

    getlogo(subscriptionId);
  }, []);

  console.log("taufq--->", props);

  return (
    <>
  <div className="whiteboxblock">

<h3 className="  mb_40  sectiontitle  ">
       Upload your logo
</h3>


<div className="container-fluid">
<div className="row mb_30  ">
          <div className="col-md-4">
            <div className="uploadPreview">
              {/* <img src={props.data} title="no-image" /> */}
              {!update ?
                <div
                  className="uploadpreviewImg"
                  dangerouslySetInnerHTML={{ __html: logo }}
                />
              :
              <img src={logo} alt="logo" />
                }
            </div>
          </div>
          <div className="col-md-8">
            <div className="Dragdrop">
              <FileUploader
                handleChange={handleChange}
                name="file"
                types={fileTypes}
              />
            </div>
          </div>
        </div>
        <div className="textAlign_center">
          <button
            className="Mainbutton width_170px"
            onClick={(e) => handleSubmit(e)}
          >
            Upload
          </button>
        </div>
</div>



</div>



      <section className="logo_upload">
     
      </section>
    </>
  );
};

export default Logo;
