import Landingsidebar from "../../Components/sidebar/landingsidebar";
import menuicon from "../../assets/images/menu-icon.svg";

import { Outlet, useNavigate, useRoutes } from "react-router-dom";
import GettingStarted from "../Quick-Guide/GettingStarted";
import HomeLanding from "../Landing-Home/HomeLanding";
import Dropdown from "react-bootstrap/Dropdown";
import Header from "../../Components/Header";
import logo from "../../assets/images/brand_logo.svg";

export const TenantLanding = () => {
  const navigate = useNavigate();
  let tenant = sessionStorage.getItem("tenantId");
  let SubscriptionId = sessionStorage.getItem("subscriptionId");
  let subs_token = sessionStorage.getItem("tenantToken")


  const gotoSubscription = () => {
    navigate(`/tenant-dashboard/${tenant}`);
  };
  const gotoBusiness = () => {
    navigate(`/businessinfo/${tenant}`);
  };
  const gotoProfile = () => {
    navigate(`/profile/${tenant}`);
  };
  const gotoChangePassword = () => {
    navigate("/change-password");
  };
  const gotoConfig = () => {
    if (SubscriptionId === null) {
      SubscriptionId = sessionStorage.getItem("subscriptionId");
    }
    console.log("Landing subscription ID::", SubscriptionId);
    navigate(`/control-panel/dashboard/${SubscriptionId}/${subs_token}`);
  };

        
    return (
        <>
            {/* <div className="logoutblock">


        <div className="logosection">
          <div className="displayFlex">
            <img src={menuicon} className="mobileMenuicon" />
            <div className="brand_logo">
              <img src={logo} className="whiteFilter" />
            </div>
          </div>
        </div>


        <ul class="user_admin">
          <li>
            <button
              className="MainButton aareRiverBrienz_Clr"
              target="_blank"

            >
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  settings
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={gotoSubscription}>
                    My Subscription
                  </Dropdown.Item>
                  <Dropdown.Item onClick={gotoBusiness}>
                    My Business
                  </Dropdown.Item>
                  <Dropdown.Item onClick={gotoProfile}>
                    My Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={gotoChangePassword}>
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item onClick={gotoConfig}>
                      Configuration
                     </Dropdown.Item>
                            
                </Dropdown.Menu>
              </Dropdown>
            </button>
          </li>





        </ul>

            </div> */}
            
                 <Header />
            <div className="page_wrapper pt0">

        <Landingsidebar />
        <div className="content_wrapper"> <Outlet /></div>


      </div>
    </>
  );
};
