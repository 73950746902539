import React from "react";
import Step1 from './assets/images/step1.png'
import Step2 from './assets/images/step2.png'
import Step3 from './assets/images/step3.png'
import Step4 from './assets/images/step4.png'
import Step5 from './assets/images/step5.png'
import Step6 from './assets/images/step6.png'
import Step7 from './assets/images/step7.png'
import Step8 from './assets/images/step8.png'
import Step9 from './assets/images/step9.png'
import Step10 from './assets/images/step10.png'
import './assets/css/howToAddServiceProvider.css'

const HowToAddServiceProviders = () => {
  return (
    <div style={{backgroundColor: '#fff'}} className="body">
      <div className="help_heading">How to add service provider</div>
      <div style={{paddingLeft: "30px"}} className="container pb30">
        <div className="row pt50 ">
          <div className="col-md-6">
            <h5>Step 1</h5>
            <p className="platinumGraniteText mb24 fs14">
              On click of "<span className="primary_color">Back Office</span>",
              view the admin interface of the application provisioned by the
              GLENZY platform with the business name in the URL.
            </p>

            <div>
              <img className="stepImg" src={Step1} alt="" />
            </div>
          </div>

          <div className="col-md-6 ">
            <div className="  ">
              <h5>Step 2</h5>
              <p className="platinumGraniteText mb24 fs14">
                On the side menu, expand the "
                <span className="primary_color">Dealer</span>" menu which will
                display the "<span className="primary_color">Manage Dealer</span>"
                sub menu.
              </p>
            </div>
            <div className=" ">
              <img className="stepImg" src={Step2} alt="" />
            </div>
          </div>
        </div>

        <div className="row pt50 ">
          <div className="col-md-6">
            <h5>Step 3</h5>
            <p className="platinumGraniteText mb24 fs14">
              On selecting the "<span className="primary_color">Manage Dealer</span>
              " sub menu, it will display all the service providers or dealers
              onboarded in the platform. Utilize the "
              <span className="primary_color">Add Dealer</span>" button to view the
              new dealer onboarding form in the application.
            </p>

            <div>
              <img className="stepImg" src={Step3} alt="" />
            </div>
          </div>

          <div className="col-md-6 ">
            <div className="  ">
              <h5>Step 4</h5>
              <p className="platinumGraniteText mb24 fs14">
                Provide the requested information in the form and then submit
                it, which will result in adding a new service provider in your
                application.
              </p>
            </div>
            <div className=" ">
              <img className="stepImg" src={Step4} alt="" />
            </div>
          </div>
        </div>

        <div className="row pt50 ">
          <div className="col-md-6">
            <h5>Step 5</h5>
            <p className="platinumGraniteText mb24 fs14">
              But the service provider needs login credentials to view the
              request through them. Hence it is necessary to map users to the
              newly onboarded dealer. On click of "
              <span className="primary_color">Dealer Users</span>" it will display
              all the dealer users irrespective of dealers.
            </p>

            <div>
              <img className="stepImg" src={Step5} alt="" />
            </div>
          </div>

          <div className="col-md-6 ">
            <div className="  ">
              <h5>Step 6</h5>
              <p className="platinumGraniteText mb24 fs14">
                The user needs to click on the "
                <span className="primary_color">Create Dealer User</span>" button
                which display the form to onboard a new dealer user.
              </p>
            </div>
            <div className=" ">
              <img className="stepImg" src={Step6} alt="" />
            </div>
          </div>
        </div>

        <div className="row pt50 ">
          <div className="col-md-6">
            <h5>Step 7</h5>
            <p className="platinumGraniteText mb24 fs14">
              The dealer users must be mapped to earlier onboarded dealers and
              they should be assigned a role. The status of the newly created
              dealer user must be "<span className="primary_color">Active</span>".
              On creating a dealer user, they will receive a mail with
              credentials to login to the application.
              <table border="1" cellpadding="4" cellspacing="2" className="fs14 mt-3 table-bordered">
                <thead>
                  <th>Role</th>
                  <th>Access rights</th>
                </thead>
                <tbody>
                  <tr>
                    <td>Dealer Admin</td>
                    <td>
                      Will be able to view all the requests and perform all
                      actions pertaining to a Dealer
                    </td>
                  </tr>
                  <tr>
                    <td>Dealer Practitioner</td>
                    <td>
                      Will be able to view all the requests and modify practice
                      date
                    </td>
                  </tr>
                  <tr>
                    <td>Dealer Member</td>
                    <td>Will be able to view all the requests</td>
                  </tr>
                </tbody>
              </table>
            </p>

            <div>
              <img className="stepImg" src={Step7} alt="" />
            </div>
          </div>

          <div className="col-md-6 ">
            <div className="  ">
              <h5>Step 8</h5>
              <p className="platinumGraniteText mb24 fs14">
                Click "<span className="primary_color">Add</span>" button
              </p>
            </div>
            <div className=" ">
              <img className="stepImg" src={Step8} alt="" />
            </div>
          </div>
        </div>

        <div className="row pt50 mb24">
          <div className="col-md-6">
            <h5>Step 9</h5>
            <p className="platinumGraniteText mb24 fs14">
              The newly created user will be listed under the dealer users list.
            </p>

            <div>
              <img className="stepImg" src={Step9} alt="" />
            </div>
          </div>

          <div className="col-md-6 ">
            <div className="  ">
              <h5>Step 10</h5>
              <p className="platinumGraniteText mb24 fs14">
                The dealer app link will be used to
              </p>
            </div>
            <div className=" ">
              <img className="stepImg" src={Step10} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToAddServiceProviders;
