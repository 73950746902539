import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Stepper from "../../Components/Stepper";
import Toast from "../../Components/Toast";
import { toast } from "react-hot-toast";
import { Formik, useFormik } from "formik";
import "react-phone-number-input/style.css";
import brandlogo from "../../assets/images/brand_logo.svg";
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import {
  Container,
  Button,
  Grid,
  Paper,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { validationSignUp } from "../../_common/Validation";
import { Post } from "../../_common/httpService";
import theecodelogo from "../../assets/images/logo_theecode.svg";
import Select from "react-select";
import { getAllInfoByISO } from "iso-country-currency";
import { countryList } from "../../_common/common";
import Header from "../../Components/Header";
import UserAvatar from "../../Components/UserAvatar/UserAvatar";

export const SignUp = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [confPasswordType, setConfPasswordType] = useState("password");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("US");

  useEffect(() => {
    const email = sessionStorage.getItem("email");
    setEmail(email);
  }, []);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfPassword = () => {
    if (confPasswordType === "password") {
      setConfPasswordType("text");
      return;
    }
    setConfPasswordType("password");
  };

  const formikSignup = useFormik({
    initialValues: {
      contactName: sessionStorage.getItem("contactName")
        ? sessionStorage.getItem("contactName")
        : "",
      email: sessionStorage.getItem("email")
        ? sessionStorage.getItem("email")
        : "",
      password: sessionStorage.getItem("confirmPassword")
        ? sessionStorage.getItem("confirmPassword")
        : "",
      confirmPassword: sessionStorage.getItem("confirmPassword")
        ? sessionStorage.getItem("confirmPassword")
        : "",
      businessName: sessionStorage.getItem("businessName")
        ? sessionStorage.getItem("businessName")
        : "",
      phoneNumber: sessionStorage.getItem("phoneNumber")
        ? sessionStorage.getItem("phoneNumber")
        : "",
      country: sessionStorage.getItem("country")
        ? sessionStorage.getItem("country")
        : "US",
    },

    validate: validationSignUp,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const countryDetails = getAllInfoByISO(formikSignup?.values?.country);

      const tenantId = sessionStorage.getItem("tenantId");
      values.tenantId = tenantId;
      for (const [key, value] of Object.entries(values)) {
        if (key !== "password") {
          sessionStorage.setItem(key, value);
        }
      }
      values.contactId = sessionStorage.getItem("contactId")
        ? parseInt(sessionStorage.getItem("contactId"))
        : 0;

      values.subscriptionId = sessionStorage.getItem("subscriptionId")
        ? sessionStorage.getItem("subscriptionId")
        : "";

      values.countryName = countryDetails?.countryName;
      values.currency = countryDetails?.currency;
      values.symbol = countryDetails?.symbol;

      await Post("subscription/tenant-signup", values)
        .then((res) => {
          if (res.statusCode === 200) {
            sessionStorage.setItem("phone", values.phoneNumber);
            toast.success("Success");
            navigate("/vertical");
            formikSignup.resetForm();
            sessionStorage.setItem(
              "contactId",
              res.data.contact[0].id ? res.data.contact[0].id : null
            );
            sessionStorage.setItem(
              "subscriptionId",
              res.data.subscription[0].id ? res.data.subscription[0].id : null
            );
          }
        })
        .catch((err) => {
          const errObj = JSON.parse(err.message);
          toast.error(errObj.data.message)
        });
    },
  });

  return (
    <div className="fullScreen_bg p0">
      <Toast />
      <div className="topHeader">
        <div className="displayFlex Alignitem_center width_100per">
          <div>
            {" "}
            <div className="logosection headerinherit">
              <div className="displayFlex">
                {/* <img src={menuicon} className="mobileMenuicon" /> */}
                <div className="brand_logo">
                  <img src={brandlogo} className="whiteFilter" />
                </div>
              </div>
            </div>
          </div>

          <div className="width_100per">
            <div>
              {" "}
              <Stepper />
            </div>
          </div>

        </div>
      </div>

      <div className="full_header">
        <div className=" ">
          <div className="pagecontentblock">
            <div className="whiteboxblock">
              <h3 className="     sectiontitle  ">
                Sign Up and explore our free offering
              </h3>

              <div className=" ">
                <form>
                  <div className="row">
                    <div className="form-group col-md-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="contactName">
                          Name<span className="star_red">*</span>
                        </label>
                        <input
                          placeholder="Enter Name"
                          id="contactName"
                          className="adminInput border_bottomgrey"
                          name="contactName"
                          onChange={(e) => {
                            formikSignup.setFieldValue(
                              "contactName",
                              e.target.value
                                .replace(/[^a-z ]/gi, "")
                                .replace(/^[ ]/, "")
                              // providernameValidation(e.target.value)
                            );
                          }}
                          onBlur={formikSignup.handleBlur}
                          value={formikSignup.values.contactName}
                        />
                      </div>

                      {formikSignup.touched.contactName &&
                      formikSignup.errors.contactName ? (
                        <p className="error_text text-danger">
                          {formikSignup.errors.contactName}
                        </p>
                      ) : null}
                    </div>

                    <div className="form-group col-md-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="businessName">
                          Business Name<span className="star_red">*</span>
                        </label>
                        <input
                          placeholder="Enter Business Name"
                          id="businessName"
                          className="adminInput border_bottomgrey"
                          name="businessName"
                          onChange={(e) => {
                            formikSignup.setFieldValue(
                              "businessName",
                              e.target.value
                                .replace(/[^a-z0-9 ]/gi, "")
                                .replace(/^[ ]/, "")
                              // .replace(/[^a-zA-Z ]/g, "")
                              // providernameValidation(e.target.value)
                            );
                          }}
                          onBlur={formikSignup.handleBlur}
                          value={formikSignup.values.businessName}
                        />
                      </div>

                      {formikSignup.touched.businessName &&
                      formikSignup.errors.businessName ? (
                        <p className="error_text text-danger">
                          {formikSignup.errors.businessName}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="email">
                          Email<span className="star_red">*</span>
                        </label>
                        <input
                          placeholder="Enter Email"
                          id="email"
                          name="email"
                          className="adminInput border_bottomgrey"
                          readOnly
                          onChange={(e) => {
                            formikSignup.setFieldValue(
                              "email",
                              e.target.value
                              // emailValidation(e.target.value)
                            );
                          }}
                          onBlur={formikSignup.handleBlur}
                          value={formikSignup.values.email}
                        />
                      </div>
                      {formikSignup.touched.email &&
                      formikSignup.errors.email ? (
                        <p className="error_text text-danger">
                          {formikSignup.errors.email}
                        </p>
                      ) : null}
                    </div>

                    <div className="form-group col-sm-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="mobileNumber">
                          Country<span className="star_red">*</span>
                        </label>
                        <select
                          className="adminInput border_bottomgrey"
                          id="country"
                          name="country"
                          onChange={(e) => {
                            formikSignup.setFieldValue(
                              "country",
                              e.target.value
                            );
                            setCountryCode(e.target.value);
                            formikSignup.setFieldValue("phoneNumber", "");
                          }}
                          onBlur={formikSignup.handleBlur}
                          value={formikSignup?.values?.country}
                        >
                          {countryList?.map((el, i) => (
                            <option
                              key={i}
                              className="border_bottomgrey"
                              value={el?.code}
                            >
                              {el?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {formikSignup?.touched?.country &&
                      formikSignup?.errors?.country ? (
                        <p className="error_text text-danger">
                          {formikSignup.errors.country}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="mobileNumber">
                          Phone Number<span className="star_red">*</span>
                        </label>
                        <PhoneInput
                          className="mobileInput border_bottomgrey"
                          defaultCountry={countryCode}
                          international
                          type="text"
                          id="mobileNumber"
                          // mask="000-00-0000"
                          placeholder="Phone Number"
                          onChange={(e) => {
                            formikSignup.setFieldValue("phoneNumber", e);
                          }}
                          name="phoneNumber"
                          onBlur={formikSignup?.handleBlur}
                          value={formikSignup?.values?.phoneNumber}
                        />
                      </div>
                      {formikSignup?.touched?.phoneNumber &&
                      formikSignup?.errors?.phoneNumber ? (
                        <p className="error_text text-danger">
                          {formikSignup.errors.phoneNumber}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6 col-xs-12 col-sm-6">
                      <div className="float-label">
                        <label htmlFor="password">
                          Password<span className="star_red">*</span>
                        </label>
                        <TextField
                          placeholder="Enter Password"
                          className="border_bottomgrey"
                          id="password"
                          name="password"
                          onChange={(e) => {
                            formikSignup.setFieldValue(
                              "password",
                              e.target.value.replace(/[ ]/g, "")
                              // providernameValidation(e.target.value)
                            );
                          }}
                          onBlur={formikSignup.handleBlur}
                          value={formikSignup.values?.password}
                          fullWidth
                          type={
                            passwordType === "password" ? "password" : "text"
                          }
                          variant="outlined"
                          InputProps={{
                            style: { height: "45px", border: "none" },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  // onClick={handlePassVisibilty}
                                  aria-label="toggle password"
                                  edge="end"
                                  onClick={togglePassword}
                                >
                                  {passwordType === "password" ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      {formikSignup.touched.password &&
                      formikSignup.errors.password ? (
                        <p
                          className="error_text text-danger mb_24"
                          style={{position: "unset"}}
                        >
                          {formikSignup.errors.password}
                        </p>
                      ) : null}
                    </div>

                    <div className="form-group col-sm-6 col-xs-12 col-sm-6">
                      <div className="float-label ">
                        <label htmlFor="confirmPassword">
                          Confirm Password<span className="star_red">*</span>
                        </label>
                        <TextField
                          placeholder="Confirm Password"
                          id="confirmPassword"
                          name="confirmPassword"
                          className="border_bottomgrey"
                          // className="passwordInput"
                          onChange={(e) => {
                            formikSignup.setFieldValue(
                              "confirmPassword",
                              e.target.value
                              // emailValidation(e.target.value)
                            );
                          }}
                          onBlur={formikSignup.handleBlur}
                          value={formikSignup.values.confirmPassword?.replace(
                            /[ ]/g,
                            ""
                          )}
                          fullWidth
                          type={
                            confPasswordType === "password"
                              ? "password"
                              : "text"
                          }
                          variant="outlined"
                          InputProps={{
                            style: { height: "45px" },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  // onClick={handlePassVisibilty}
                                  aria-label="toggle password"
                                  edge="end"
                                  onClick={toggleConfPassword}
                                >
                                  {confPasswordType === "password" ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      {formikSignup.touched.confirmPassword &&
                      formikSignup.errors.confirmPassword ? (
                        <p className="error_text text-danger">
                          {formikSignup.errors.confirmPassword}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="textAlign_center mt_20">
                    <button
                      className="Mainbutton  "
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        formikSignup.submitForm();
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
