import React, { useState } from "react";
import { useFormik } from "formik";
import { AuthPost } from "../../_common/httpService";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import Toast from "../../Components/Toast";
import { toast } from "react-hot-toast";
import EyeHide from "../../assets/slices/eye_hide.svg";
import EyeShow from "../../assets/slices/eye_show.svg";
const ChangePassword = () => {
  let tenantId = sessionStorage.getItem("tenantId");
  let navigate = useNavigate();
  const [passwordVisibilty, setPasswordVisibilty] = useState("password")
  const [passwordVisibiltynp, setPasswordVisibiltynp] = useState("password")
  const [passwordVisibiltycp, setPasswordVisibiltycp] = useState("password")
  const [respo, setRespo] = useState(true)

  const validateC = (values) => {
    const errors = {};
    if (!values.oldPassword) {
      errors.oldPassword = "Old password is required";
    } else if (values.newPassword.length < 8) {
    }

    if (!values.newPassword) {
      errors.newPassword = "New password is required";
      setRespo(true)
    } else if( !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i.test(values.newPassword)) {
      errors.newPassword ="Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      setRespo(false)
    } else {
      setRespo(true)
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password is required";
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = "New password and confirm password should match";
    }

    return errors;
  };
  const { values, touched, errors, handleSubmit, handleChange } = useFormik({
    initialValues: {
      newPassword: "",
      oldPassword: "",
      confirmPassword: "",
    },
    validate: validateC,
    onSubmit: (values) => {
      AuthPost(`tenant/changePassword/${tenantId}`, values).then((res) => {
        if (res.statusCode == 200) {
          toast.success("Password Updated")
          navigate(`/dashboard`);
        } else {
          toast.error(res.message)
        }
      });
    },
  });

  return (
    <>
      <Toast />
      <div className="fullScreen_bg p0">
        <div className="">
          <div className="  pb_24">
            <Header />


            <div className="pagecontentblock">

              <div className="container">
                <div className="row">
                  <div className="col-md-12 changePassword">

                    <div className=" ">
                      <div className="grayFrame">
                        <div className="grayHeader">
                          <h2 className="headingTitle24 HorrorSnob_Color">

                          </h2>
                        </div>
                        <div className="otpblock">

                          <h5 className="scatteredShowersText mb_24  ">
                            Change Password
                          </h5>

                          <form onSubmit={handleSubmit}>
                            <div className=" ">
                              <label htmlFor="current_password" className="labeltext">
                                Current Password
                              </label>
                              <div className="formGroup">
                                <input
                                  className="Main_input"
                                  type={passwordVisibilty}
                                  name="oldPassword"
                                  id="current_password"
                                  onChange={handleChange}
                                  value={values.oldPassword}
                                />
                                <div className="input-group-prepend">
                                  <span className="inputGroup_text">
                                    <img
                                      className="width20"
                                      src={
                                        passwordVisibilty == "password"
                                          ? EyeHide
                                          : EyeShow
                                      }
                                      onClick={() => {
                                        passwordVisibilty == "password"
                                          ? setPasswordVisibilty("text")
                                          : setPasswordVisibilty("password");
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                              {errors.oldPassword && touched.oldPassword ? (
                                <div className="crror_text">{errors.oldPassword}</div>
                              ) : null}
                            </div>
                            <div className="row mb_40 pt10">
                              <div className="col-md-12 changePassword">
                                <label htmlFor="new_password" className="labeltext">
                                  New Password
                                </label>
                                <div className="formGroup">
                                  <input
                                    className="Main_input"
                                    type={passwordVisibiltynp}
                                    name="newPassword"
                                    id="new_password"
                                    onChange={handleChange}
                                    value={values.newPassword}
                                  />
                                  <div className="input-group-prepend">
                                    <span className="inputGroup_text">
                                      <img
                                        className="width20"
                                        src={
                                          passwordVisibiltynp == "password"
                                            ? EyeHide
                                            : EyeShow
                                        }
                                        onClick={() => {
                                          passwordVisibiltynp == "password"
                                            ? setPasswordVisibiltynp("text")
                                            : setPasswordVisibiltynp("password");
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                {errors.newPassword && touched.newPassword ? (
                                  <div className="crror_text">{errors.newPassword}</div>
                                ) : null}
                              </div>
                              <div className={`col-md-12 ${respo ? "changePassword" : "changcPassword"}`}>
                                <label htmlFor="confirm_password" className="labeltext">
                                  Confirm Password
                                </label>
                                <div className="formGroup">
                                  <input
                                    className="Main_input"
                                    type={passwordVisibiltycp}
                                    name="confirmPassword"
                                    id="confirm_password"
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                  />
                                  <div className="input-group-prepend">
                                    <span className="inputGroup_text">
                                      <img
                                        className="width20"
                                        src={
                                          passwordVisibiltycp == "password"
                                            ? EyeHide
                                            : EyeShow
                                        }
                                        onClick={() => {
                                          passwordVisibiltycp == "password"
                                            ? setPasswordVisibiltycp("text")
                                            : setPasswordVisibiltycp("password");
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>

                                {errors.confirmPassword && touched.confirmPassword ? (
                                  <div className="crror_text">
                                    {errors.confirmPassword}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className=" textAlign_center">
                              <button type="submit" className="Mainbutton width_200px">
                                Change Password
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>


                  </div></div></div>
            </div>




          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
