export const initialState = {
  showToast: false,
  toastMessage: "",
  toastType: "",
  globalStep: null,
  currentStep: null,
  socketObj: null,
  pieChartsData: {},
  averageLoansData: [],
  monthWiseLoansDisbursed: [],
  currency_symbol: ''
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOAST":
      return {
        ...state,
        showToast: true,
        toastMessage: action.toastMessage,
        toastType: action.toastType,
      };

    case "SET_STEP":
      return {
        ...state,
        globalStep: action.payload,
      };

    case "SET_CURRENT_STEP":
      return {
        ...state,
        currentStep: action.payload,
      };

    case "SET_SOCKET_OBJ":
      return {
        ...state,
        socketObj: action.payload,
      };

      case "SET_PIECHARTS_DATA":
      return {
        ...state,
        pieChartsData: action.payload,
      };

      case "SET_AVGLOANS_DATA":
      return {
        ...state,
        averageLoansData: action.payload,
      };

      case "SET_MONTHWISE_DISBURSED":
      return {
        ...state,
        monthWiseLoansDisbursed: action.payload,
      };

      case "SET_CURRENCY_SYMBOL":
        return {
          ...state,
          currency_symbol: action.payload
        }

    default:
      return state;
  }
};
