import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import solarImage from "../../assets/images/subcategory-04.png";
import { AuthGet, AuthPut } from "../../_common/httpService";
import Stepper from "../../Components/Stepper";
import { useStateValue } from "../../Context/StateProvider";
import Toast from "../../Components/Toast";
import { toast } from "react-hot-toast";
import theecodelogo from "../../assets/images/logo_theecode.svg";

const Product = () => {
  const [subProduct, setSubProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [isSelect, setIsselect] = useState(false)
  let { id } = useParams();
  let navigate = useNavigate();
  let verticalName = sessionStorage.getItem("selectedVertical");
  const [{ }, dispatch] = useStateValue();

  // *** To get All subproducts ***//
  useEffect(() => {
    AuthGet(`subproduct/list/${id}`).then((res) => {
      setSubProduct(res.subProducts);
    });
  }, [id]);

  // *** To get product id  ***//
  let handleClick = (e, value) => {
    e.preventDefault();
    setIsselect(true)
    return setSelectedProduct(value);
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem("ProductId", selectedProduct);
    const subParams = {
      subscriptions: [
        {
          productId: selectedProduct,
          tenantId: sessionStorage.getItem("tenantId"),
          verticalId: id,
        },
      ],
    };
    let subscriptionId = sessionStorage.getItem("subscriptionId");
    AuthPut("subscription/update/" + subscriptionId, subParams)
      .then((res) => {
        if (res.statusCode === 200) {
          toast.success(res.message[0])
          navigate("/upload-logo");
        }
      })
      .catch((error) => console.log(error));
  };
  console.log("sss", selectedProduct, subProduct)
  return (
    <div className="fullScreen_bg p0">
      <Toast />
      <div className="full_header">
        <div className="Customerpanel_rectangle box_shadow_none border_radius0">
          <div className="Custmpanel_rectangle_header displayFlex Alignitem_center">
            <div className="">
              <img src={theecodelogo} />
            </div>
            <Stepper />
          </div>

          <div className="plr80_ptb40">
            <h3 className="HorrorSnob_Color textAlign_center mb_50 headingTitle24">
              {verticalName}
            </h3>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="mb_50">
                {subProduct.map((productItem) => (
                  <div
                    className="sub_product"
                    key={productItem.id}
                    onClick={(e) => handleClick(e, productItem.id)}
                  >
                    <label>
                      <input
                        type="radio"
                        name="subproduct"
                        style={{ display: "none" }}
                      />

                      <div className="productItem">
                        <div>
                          <img src={solarImage} alt={"ProductImage"} />
                        </div>
                        <div className="productItemBody">
                          <h3>{productItem.name}</h3>
                          <p>{productItem.description}</p>
                        </div>
                      </div>
                      {isSelect ? (
                        <div className="selectBtn selected">Selected</div>
                      ) : (
                        <div className="selectBtn">Select</div>
                      )}
                    </label>
                  </div>
                ))}
              </div>
              <div className="textAlign_center">
                <button
                  className="Mainbutton width_170px"
                  type="submit"
                  onSubmit={(e) => handleSubmit(e)}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
