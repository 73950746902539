import React, { useState } from 'react'
import { useFormik } from "formik";
import { AuthPost } from "../../_common/httpService";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Toast from "../../Components/Toast";
import { toast } from "react-hot-toast";
import { useLocation } from 'react-router-dom';
import EyeHide from "../../assets/slices/eye_hide.svg";
import EyeShow from "../../assets/slices/eye_show.svg";
import Header from "../../Components/Header";
import mainLogo from "../../assets/slices/main_logo.svg";
import {validationResetPassword} from "../../_common/Validation"

const ResetPassword = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [passwordVisibilty, setPasswordVisibilty] = useState("password")
    const [passwordVisibiltynp, setPasswordVisibiltynp] = useState("password")
    const [passwordVisibiltycp, setPasswordVisibiltycp] = useState("password")
    const token = new URLSearchParams(location.search).get('token');
    const id = new URLSearchParams(location.search).get('id');
    // const validate = (values) => {
    //     const errors = {};
    //     // if (!values.oldPassword) {
    //     //     errors.oldPassword = "Old password is required";
    //     // } else if (values.newPassword.length < 8) {
    //     // }

    //     if (!values.newPassword) {
    //         errors.newPassword = "New password is required";
    //     } else if (values.newPassword.length < 8) {
    //         errors.newPassword = "Password requires minimum 8 characters";
    //     }

    //     if (!values.confirmPassword) {
    //         errors.confirmPassword = "Confirm password is required";
    //     } else if (values.confirmPassword.length < 8) {
    //         errors.confirmPassword = "Password requires minimum 8 characters";
    //     } else if (values.newPassword !== values.confirmPassword) {
    //         errors.confirmPassword = "New password and confirm password should match";
    //     }

    //     return errors;
    // };
    const { values, touched, errors, handleSubmit, handleChange, handleBlur } = useFormik({
        initialValues: {
            newPassword: "",
            // oldPassword: "",
            confirmPassword: "",
        },
        validate : validationResetPassword,
        enableReinitialize: true,
        onSubmit: (values) => {
          let payload={
            newpw:values.newPassword,
            user_id: id
          }
            AuthPost(`tenant/passwordReset`, payload).then((res) => {
                if (res.statusCode == 200) {
                    toast.success("Password Changed")
                    navigate(`/login`);
                } else {
                    toast.error(res.message)
                }
            });
        },
    });
    return (
      <>
        <Toast />
        <div className="fullScreen_bg">
          <div className="container_wrapper">
            <div className="Customerpanel_rectangle pb_24">
              {/* <Header /> */}

<div className="Main_header">
    
    <div className="navbar_brand">
       <img src={mainLogo} />
        
            </div>
</div>





              <div className="p24">
                <div className="grayFrame">
                  <div className="grayHeader">
                    <h2 className="headingTitle24 HorrorSnob_Color">
                      Reset Password
                    </h2>
                  </div>
                  <div className="grayContent">
                    <form onSubmit={handleSubmit}>
                      {/* <div className="mb_24">
                        <label htmlFor="current_password" className="labeltext">
                          Current Password
                        </label>
                        <div className="formGroup">
                          <input
                            className="Main_input"
                            type={passwordVisibilty}
                            name="oldPassword"
                            id="current_password"
                            onChange={handleChange}
                            value={values.oldPassword}
                          />
                          <div className="input-group-prepend">
                            <span className="inputGroup_text">
                              <img
                                className="width20"
                                src={
                                  passwordVisibilty == "password"
                                    ? EyeHide
                                    : EyeShow
                                }
                                onClick={() => {
                                  passwordVisibilty == "password"
                                    ? setPasswordVisibilty("text")
                                    : setPasswordVisibilty("password");
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        {errors.oldPassword && touched.oldPassword ? (
                          <div className="error_text">{errors.oldPassword}</div>
                        ) : null}
                      </div> */}
                      <div className="row mb_40">
                        <div className="col-md-6">
                          <label htmlFor="new_password" className="labeltext">
                            New Password
                          </label>
                          <div className="formGroup">
                            <input
                              className="Main_input"
                              type={passwordVisibiltynp}
                              name="newPassword"
                              id="new_password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.newPassword.replace(/[ ]/,"")}
                              
                            />
                            <div className="input-group-prepend">
                              <span className="inputGroup_text">
                                <img
                                  className="width20"
                                  src={
                                    passwordVisibiltynp == "password"
                                      ? EyeHide
                                      : EyeShow
                                  }
                                  onClick={() => {
                                    passwordVisibiltynp == "password"
                                      ? setPasswordVisibiltynp("text")
                                      : setPasswordVisibiltynp("password");
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          {errors.newPassword && touched.newPassword ? (
                            <div className="error_text">
                              {errors.newPassword}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="confirm_password" className="labeltext">
                            Confirm Password
                          </label>
                          <div className="formGroup">
                            <input
                              className="Main_input"
                              type={passwordVisibiltycp}
                              name="confirmPassword"
                              id="confirm_password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword.replace(/[ ]/,"")}
                            />
                            <div className="input-group-prepend">
                              <span className="inputGroup_text">
                                <img
                                  className="width20"
                                  src={
                                    passwordVisibiltycp == "password"
                                      ? EyeHide
                                      : EyeShow
                                  }
                                  onClick={() => {
                                    passwordVisibiltycp == "password"
                                      ? setPasswordVisibiltycp("text")
                                      : setPasswordVisibiltycp("password");
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          {errors.confirmPassword && touched.confirmPassword ? (
                            <div className="error_text">
                              {errors.confirmPassword}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="textAlign_center">
                        <button
                          type="submit"
                          className="Mainbutton width_200px"
                        >
                          Change Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default ResetPassword